import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { connect } from 'react-redux';
import { AppState, Selectors, selectors, ActionCreators, actionCreators } from 'store';
import { Client } from 'store/clients';
import { MarginProfile } from 'store/marginProfiles';
import { FloatingMargin } from 'store/floatingMargins';

export interface ClientsMarginsStateProps {
  isFetching: boolean;
  clients: readonly Client[];
  marginProfiles: Record<number, MarginProfile>;
  floatingMargins: Record<string, FloatingMargin>;
}

export interface ClientsMarginsDispatchProps {
  toggleClientModal(): void;
  goToPresets(): void;
}

const mapStateToProps: MapStateToPropsHOF<ClientsMarginsStateProps, {}, AppState, Selectors> = (
  sl,
) => (state) => ({
  isFetching:
    sl.isClientsFetching(state) ||
    sl.isMarginProfilesFetching(state) ||
    sl.isUserDataFetching(state),
  clients: sl.getFilteredClients(state),
  marginProfiles: sl.getMarginProfiles(state) as Record<number, MarginProfile>,
  floatingMargins: sl.getAllFloatingMarginsRecord(state) as Record<string, FloatingMargin>,
});

const mapDispatchToProps: MapDispatchToPropsHOF<ClientsMarginsDispatchProps, {}, ActionCreators> = (
  ac,
) => (dispatch) => ({
  toggleClientModal() {
    dispatch(ac.clientForm.clientFormModalOpened());
  },
  goToPresets() {
    dispatch(ac.ui.pageChanged('MarginsPresets'));
  },
});

export const connectClientsMargins = connect(
  mapStateToProps(selectors),
  mapDispatchToProps(actionCreators),
);
