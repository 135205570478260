import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connectCustomOnlyFilter } from './connect/connectCustomOnlyFilter';
import { FilterGroup } from './FilterGroup';

export interface CustomOnlyFilterProps {
  value: boolean;
  onToggle(): void;
}

const CustomOnlyFilterRaw: React.FunctionComponent<CustomOnlyFilterProps> = ({
  value,
  onToggle,
}) => {
  const { formatMessage } = useIntl();
  return (
    <FilterGroup label={formatMessage({ id: 'filters.standardOrCustomizedMargins' })}>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          id="customizedMarginsOnly"
          className="custom-control-input"
          checked={value}
          onChange={onToggle}
        />
        <label htmlFor="customizedMarginsOnly" className="custom-control-label">
          <FormattedMessage id="filters.customizedMarginsOnly" />
        </label>
      </div>
    </FilterGroup>
  );
};

export const CustomOnlyFilter = connectCustomOnlyFilter(CustomOnlyFilterRaw);
