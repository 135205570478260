import { AppThunk } from 'store';
import { getClientById } from 'store/clients';
import { assertDefined } from 'utils/asserts';
import { getClientForm, getClientFormValues } from './clientFormSelectors';
import { CustomMarginProfileId } from './clientFormSlice';

export const openEditClientForm = (clientId: number): AppThunk => (
  dispatch,
  getState,
  { selectors: sl, actionCreators: ac },
) => {
  const state = getState();
  const client = sl.getClientById(state, clientId);
  dispatch(ac.clientForm.clientFormModalOpened(client));
};

export const changeClientName = (clientName: string): AppThunk => (
  dispatch,
  getState,
  { selectors: sl, actionCreators: ac },
) => {
  const state = getState();
  const foundClient = sl.getAllClientsArray(state).find((x) => x.name === clientName);
  if (foundClient === undefined) {
    dispatch(ac.clientForm.clientFormDisplayModeChanged({ displayMode: 'Add' }));
    dispatch(ac.clientForm.clientFormFieldChanged({ name: clientName }));
  } else {
    dispatch(
      ac.clientForm.clientFormDisplayModeChanged({
        displayMode: 'SoftEdit',
        client: foundClient,
      }),
    );
  }
};

export const submitClientForm = (): AppThunk => async (
  dispatch,
  getState,
  { api, actionCreators: ac },
) => {
  const state = getState();

  const {
    name,
    lowerMaturityRange,
    upperMaturityRange,
    salesMarginProfileId,
    devLMarginProfileId,
    pcruMargin,
    changeReason,
    isActive,
  } = getClientFormValues(state);
  assertDefined(name, 'Undefined name');
  assertDefined(salesMarginProfileId, 'Undefined salesMarginProfileId');
  assertDefined(devLMarginProfileId, 'Undefined devLMarginProfileId');
  assertDefined(pcruMargin, 'Undefined pcruMargin');

  const clientId = getClientForm(state).clientId;
  const existingClientSalesMarginProfileId =
    clientId !== undefined ? getClientById(state, clientId)?.salesMarginProfileId : undefined;

  // If we are updating and sales margin profile was changed, the user needs to provide a reason
  if (
    clientId !== undefined &&
    salesMarginProfileId !== existingClientSalesMarginProfileId &&
    changeReason === undefined
  ) {
    dispatch(ac.clientForm.clientFormReasonModalToggled());
    return;
  }

  dispatch(ac.clientForm.clientFormSubmitSent());

  const client =
    clientId === undefined
      ? await api.clients.addClient({
          name,
          lowerMaturityRange,
          upperMaturityRange,
          salesMarginProfileId,
          devLMarginProfileId,
          pcruMargin,
          isActive,
        })
      : await api.clients.updateClient({
          id: clientId,
          name,
          lowerMaturityRange,
          upperMaturityRange,
          salesMarginProfileId,
          devLMarginProfileId,
          pcruMargin,
          isActive,
          reasonForChange: changeReason,
        });

  // In case of custom margin profile creation, re-fetch profiles
  if (
    salesMarginProfileId === CustomMarginProfileId &&
    (clientId === undefined || existingClientSalesMarginProfileId !== CustomMarginProfileId)
  ) {
    await dispatch(ac.marginProfiles.fetchMarginProfiles());
  }

  const floatingMargins = await api.floatingMargins.getClientFloatingMargins();

  dispatch(ac.clientForm.clientFormSubmitReceived({ client, floatingMargins }));
  if (state.clientForm.displayMode === 'SoftEdit') {
    dispatch(ac.filters.clientNameFilterChanged(name));
  }
};
