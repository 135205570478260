import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Select } from 'components/shared/Form/Select';
import { changeReasons } from 'store/clients';
import { connectChangeReasonModal } from './connectChangeReasonModal';
import { LoadingButton } from 'components/shared/LoadingButton';

export interface ChangeReasonModalProps {
  isOpen: boolean;
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
  clientName: string;
  value: string | undefined;
  selectReason(value: string): void;
  toggle(): void;
  submit(): void;
}

const options: [string, string][] = changeReasons.map(x => [x, x]);

const ChangeReasonModalRaw: React.FunctionComponent<ChangeReasonModalProps> = ({
  isOpen,
  isReadyToSubmit,
  isSubmitting,
  clientName,
  value,
  selectReason,
  toggle,
  submit,
}) => (
  <Modal isOpen={isOpen}>
    <ModalHeader tag="h3" toggle={toggle}>
      <FormattedMessage id="changeReasonModal.title" />
    </ModalHeader>
    <ModalBody>
      <div className="form-group">
        <label className="font-weight-normal text-primary">
          <FormattedMessage
            id="changeReasonModal.selectReason"
            values={{
              client: clientName,
              strong: (msg: string) => <strong className="text-secondary">{msg}</strong>,
            }}
          />
        </label>
        <Select value={value} options={options} onChange={selectReason} />
      </div>
    </ModalBody>
    <ModalFooter>
      <button className="btn btn-lg btn-flat-secondary" onClick={toggle}>
        <FormattedMessage id="common.cancel" />
      </button>
      <LoadingButton
        className="btn btn-lg btn-primary"
        onClick={submit}
        loading={isSubmitting}
        disabled={!isReadyToSubmit || isSubmitting}
      >
        <FormattedMessage id="common.save" />
      </LoadingButton>
    </ModalFooter>
  </Modal>
);

export const ChangeReasonModal = connectChangeReasonModal(ChangeReasonModalRaw);
