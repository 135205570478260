import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { marginProfilesEditionSlice } from 'store/marginProfilesEdition/marginProfilesEditionSlice';
import {
  defaultFetchableState,
  createFetchableReducers,
  FetchableState,
} from 'store/utils/fetchable';

export type MarginProfileType = 'Sales' | 'DevL' | 'NA';

interface MarginProfileBase {
  id: number;
  name: string;
  isPreset: boolean;
}

export interface SalesMarginProfile extends MarginProfileBase {
  type: 'Sales';
  fixedMargins: MarginByMaturity[];
  floating3M: MarginByMaturity[];
  floating6M: MarginByMaturity[];
  floating12M: MarginByMaturity[];
}

export interface DevlMarginProfile extends MarginProfileBase {
  type: 'DevL';
  margins: MarginByMaturity[];
}

export interface MarginWithoutProfile extends MarginProfileBase {
  type: 'NA';
  techMarginsFixed: MarginByMaturity[];
  techMarginsFloating: MarginByMaturity[];
  adjustmentMargins: MarginByMaturity[];
}

export type MarginProfile = SalesMarginProfile | DevlMarginProfile | MarginWithoutProfile;

export const isSalesMargin = (profile: MarginProfile): profile is SalesMarginProfile => {
  return profile.type === 'Sales';
};
export const isDevlMargin = (profile: MarginProfile): profile is DevlMarginProfile => {
  return profile.type === 'DevL';
};

export const getMargins = (profile: MarginProfile) =>
  isSalesMargin(profile) ? profile.fixedMargins : isDevlMargin(profile) ? profile.margins : [];

export interface MarginByMaturity {
  id: string;
  maturity: string;
  value: number;
}

export const marginProfilesAdapter = createEntityAdapter<MarginProfile>();

type MarginProfilesState = EntityState<MarginProfile> & FetchableState;

const initialState: MarginProfilesState = {
  ...marginProfilesAdapter.getInitialState(),
  ...defaultFetchableState,
};

export const marginProfilesSlice = createSlice({
  name: 'marginProfiles',
  initialState,
  reducers: {
    ...createFetchableReducers<MarginProfilesState, MarginProfile>({
      fetched: marginProfilesAdapter.addMany,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(marginProfilesEditionSlice.actions.editionSaved, (state, { payload }) => {
      marginProfilesAdapter.upsertMany(state, payload.marginProfiles);
    }),
});
