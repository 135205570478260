import { Matomo, MatomoUtils } from 'utils/matomo';
import { appConfig } from 'config';

let _matomo: Matomo;

export const getMatomo = () => {
  if (_matomo === undefined) {
    if (appConfig.matomo_url) {
      _matomo = new MatomoUtils({
        url: appConfig.matomo_url,
        siteId: appConfig.matomo_siteid,
      });
    } else {
      const logFn = (...args: any[]) => console.log(`[Matomo] ${args.join(' - ')}`);
      _matomo = {
        init: logFn,
        track: logFn,
      };
    }
  }
  return _matomo;
};
