import { actionCreators, ActionCreators, Selectors, selectors } from 'store';
import { configureStore, combineReducers, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { SGWTConnectCore } from '@sgwt/connect-core';
import { WmcApi } from 'api';
import { filtersSlice } from './filters';
import { uiSlice } from './ui';
import { clientsSlice } from './clients';
import { marginProfilesSlice } from './marginProfiles';
import { userDataSlice } from './userData';
import { clientFormSlice } from './clientForm';
import { clientEditionSlice } from './clientEdition';
import { marginProfilesEditionSlice } from './marginProfilesEdition/marginProfilesEditionSlice';
import { catchMiddleware } from './error/catchMiddleware';
import { errorSlice } from './error/errorSlice';
import { getMatomoMiddleware } from './matomoMiddleware';
import { Matomo } from 'utils/matomo';
import { sgwtMiddleware } from './ui/sgwtMiddleware';
import { clientDuplicationSlice } from './clientDuplication/clientDuplicationSlice';
import { displayedProductsSlice } from './displayedProducts';
import { floatingMarginsSlice } from './floatingMargins';

const rootReducer = combineReducers({
  ui: uiSlice.reducer,
  error: errorSlice.reducer,
  clients: clientsSlice.reducer,
  clientForm: clientFormSlice.reducer,
  clientEdition: clientEditionSlice.reducer,
  clientDuplication: clientDuplicationSlice.reducer,
  floatingMargins: floatingMarginsSlice.reducer,
  marginProfiles: marginProfilesSlice.reducer,
  marginProfileEdition: marginProfilesEditionSlice.reducer,
  filters: filtersSlice.reducer,
  displayedProducts: displayedProductsSlice.reducer,
  userData: userDataSlice.reducer,
});

export const createStore = (api: WmcApi, sgwt: SGWTConnectCore, matomo: Matomo) =>
  configureStore({
    reducer: rootReducer,
    middleware: [
      catchMiddleware,
      sgwtMiddleware(sgwt),
      getMatomoMiddleware(matomo),
      ...getDefaultMiddleware({
        thunk: {
          extraArgument: {
            api,
            actionCreators,
            selectors,
          } as ThunkExtraArgument,
        },
      }),
    ] as const,
  });

export type AppState = ReturnType<typeof rootReducer>;
export type Selector<T, S extends Partial<AppState> = AppState> = (state: S) => T;
export type AppThunk<R = void> = ThunkAction<R, AppState, ThunkExtraArgument, Action<string>>;
export interface ThunkExtraArgument {
  api: WmcApi;
  actionCreators: ActionCreators;
  selectors: Selectors;
}
export type AppDispatch = ReturnType<typeof createStore>['dispatch'];
