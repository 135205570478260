import React from 'react';
import { Spinner } from './Spinner';

export type LoadingButtonProps = {
  loading: boolean;
} & JSX.IntrinsicElements['button'];

export const LoadingButton: React.FunctionComponent<LoadingButtonProps> = ({
  loading,
  children,
  ...props
}) => (
  <button {...props}>
    {loading && <Spinner className="ml-n1 mr-2" />}
    {children}
  </button>
);
