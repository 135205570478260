import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetRowNodeIdFunc,
  GridApi,
  ICellRendererParams,
} from 'ag-grid-community';
import { useFeatureToggles } from 'components/hooks/useFeatureToggles';
import { AgGridReactWithDefaults } from 'components/shared/agGrid/AgGridReactWithDefaults';
import {
  leftGroupHeaderClass,
  maturityClass,
  maturityHeaderClass,
  rightBorderClass,
  rightGroupHeaderClass,
  topHeaderClass,
} from 'components/shared/agGrid/constants';
import { TableContainer } from 'components/shared/agGrid/TableContainer';
import React, { useEffect, useRef } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { Products } from 'store/displayedProducts';
import {
  isDevlMargin,
  isSalesMargin,
  MarginByMaturity,
  MarginProfile,
  MarginProfileType,
} from 'store/marginProfiles';
import { MarginProfileDiffs } from 'store/marginProfilesEdition/marginProfilesEditionSlice';
import { FeatureToggles } from 'store/userData';
import { range } from 'utils/range';
import { connectMarginsPresetsTable } from './connect/connectMarginsPresetsTable';

export interface MarginsPresetsTableProps {
  marginPresets: readonly MarginProfile[];
  isEditing: boolean;
  diffs: MarginProfileDiffs;
  productFilter?: Products[] | undefined;
  onMarginModified(marginId: string, value: number): void;
}

interface MarginPresetsRow {
  id: number;
  type: MarginProfileType | 'Custom' | 'Settings';
  name: string;
  marginsType: 'fixedMargins' | 'floating3M' | 'floating6M' | 'floating12M' | 'techMarginsFixed' | 'techMarginsFloating' | 'adjustmentMargins';
  margins: MarginByMaturity[];
  diff: MarginProfileDiffs;
}

interface MarginPresetsContext {
  isEditing: boolean;
}

const getColumnDef = (
  formatMessage: IntlFormatters['formatMessage'],
  onMarginModified: MarginsPresetsTableProps['onMarginModified'],
  features: FeatureToggles,
): (ColGroupDef | ColDef)[] => [
  {
    field: 'marginProfileType',
    children: [
      {
        field: 'marginProfileType',
        rowGroup: true,
        hide: true,
        valueGetter: (params) => {
          if (!features.capFlooredActivated) {
            return formatMessage({
              id: `marginPresets.table.marginType.fixedMarginsOld`,
            });
          }
          return formatMessage({
            id: `marginsPresets.table.marginProfileType.${(params.data as MarginPresetsRow).type}`,
          });
        },
      },
    ],
  },
  {
    field: 'marginProfileName',
    children: [
      {
        field: 'name',
        rowGroup: true,
        hide: true,
      },
    ],
  },
  {
    headerName: formatMessage({ id: 'marginsPresets.table.marginProfile' }),
    headerClass: `${topHeaderClass} ${leftGroupHeaderClass}`,
    children: [
      {
        colId: 'marginName',
        headerName: '',
        pinned: 'left',
        minWidth: 240,
        showRowGroup: true,
        cellRenderer: 'agGroupCellRenderer',
        onCellClicked: (e) => e.node.setExpanded(!e.node.expanded),
        cellRendererParams: {
          suppressCount: true,
        },
        headerClass: rightBorderClass,
        cellClass: rightBorderClass,
        cellClassRules: {
          'font-weight-bold': (params: CellClassParams) => params.node.group,
        },
        valueGetter: (params) => {
          const data = params.data as MarginPresetsRow;
          return (params.node.parent?.allChildrenCount ?? 0) > 1
            ? formatMessage({
                id: `marginPresets.table.marginType.${data.marginsType}`,
              })
            : data.name;
        },
      },
    ],
  },
  {
    headerName: formatMessage({ id: 'marginsPresets.table.marginByMaturity' }),
    headerClass: `${topHeaderClass}`,
    children: range(1, 30).map((maturity) => ({
      headerName: maturity.toString(),
      field: maturity.toString(),
      minWidth: 60,
      headerClass: `${maturityHeaderClass} text-secondary`,
      cellClass: `${maturityClass} text-right`,
      editable: (params) => (params.context as MarginPresetsContext).isEditing,
      valueGetter: (params) => {
        if (!params.data) return;
        console.warn(params);
        const data = params.data as MarginPresetsRow;
        const margin = data.margins.find((margin) => margin.maturity === maturity.toString());
        return margin?.id !== undefined ? data.diff[margin?.id] ?? margin.value : '-';
      },
      valueSetter: ({ data, newValue }) => {
        const value = Number(newValue);
        if (!Number.isNaN(value)) {
          const marginId = (data as MarginPresetsRow).margins.find(
            (margin) => margin.maturity === maturity.toString(),
          )?.id;
          if (marginId !== undefined) {
            onMarginModified(marginId, value);
          }
        }
        return false;
      },
    })),
  },
  {
    headerName: formatMessage({ id: 'marginsPresets.table.action' }),
    pinned: 'right',
    headerClass: `${topHeaderClass} ${rightGroupHeaderClass}`,
    children: [
      {
        headerName: '',
        field: 'action',
        minWidth: 80,
        pinned: 'right',
        headerClass: rightGroupHeaderClass,
        cellClass: rightGroupHeaderClass,
        cellStyle: { left: '1px' },
        cellRendererFramework: (params: ICellRendererParams) =>
          !params.node.group && <HistoryIcon />,
      },
    ],
  },
];

const productToMarginProp: Record<
  Products,
  'fixedMargins' | 'floating3M' | 'floating6M' | 'floating12M'
> = {
  fixed: 'fixedMargins',
  '3M': 'floating3M',
  '6M': 'floating6M',
  '12M': 'floating12M',
};

const getRowData = (
  marginPresets: readonly MarginProfile[],
  diffs: MarginProfileDiffs,
  productFilter: Products[],
  features: FeatureToggles,
): MarginPresetsRow[] => {
  const displayedProducts = getDisplayedProducts(productFilter, features);
  return marginPresets.flatMap<MarginPresetsRow>((profile) =>
    isSalesMargin(profile)
      ? displayedProducts.map((product) => ({
          type: profile.isPreset ? 'Sales' : 'Custom',
          id: profile.id,
          name: profile.name,
          marginsType: productToMarginProp[product],
          margins: profile[productToMarginProp[product]],
          diff: diffs,
        }))
      : isDevlMargin(profile)
      ? [
          {
            type: features.capFlooredActivated ? 'Settings' : 'DevL',
            id: profile.id,
            name: features.capFlooredActivated ? 'Devl ' + profile.name : profile.name,
            marginsType: 'fixedMargins',
            margins: profile.margins,
            diff: diffs,
          },
        ]
      : features.capFlooredActivated ? [
        {
          type: 'Settings',
          id: profile.id,
          name: 'Tech Margin Fixed',
          marginsType: 'techMarginsFixed',
          margins: profile.techMarginsFixed,
          diff: diffs,
        },
        {
          type: 'Settings',
          id: profile.id,
          name: 'Tech Margin Floating',
          marginsType: 'techMarginsFloating',
          margins: profile.techMarginsFloating,
          diff: diffs,
        },
        {
          type: 'Settings',
          id: profile.id,
          name: 'Adjustment Margin Floating',
          marginsType: 'adjustmentMargins',
          margins: profile.adjustmentMargins,
          diff: diffs,
        },
      ]: [],
  );
};

const getDisplayedProducts = (productFilter: Products[], features: FeatureToggles): Products[] => {
  if (features.capFlooredActivated) {
    return ['fixed'];
  } else if (productFilter.length > 0) {
    return productFilter;
  } else {
    return ['3M', '6M', '12M', 'fixed'];
  }
};

const getPresetRowNodeId: GetRowNodeIdFunc = (data: MarginPresetsRow) => {
  return data.name + data.marginsType;
};

const MarginsPresetsTableRaw: React.FunctionComponent<MarginsPresetsTableProps> = ({
  marginPresets,
  isEditing,
  diffs,
  productFilter,
  onMarginModified,
}) => {
  const features = useFeatureToggles();
  const agGridApi = useRef<{ api: GridApi; columnApi: ColumnApi } | undefined>(undefined);
  const context = useRef<MarginPresetsContext>({ isEditing });
  useEffect(() => {
    context.current.isEditing = isEditing;
    agGridApi.current?.api.refreshCells({ force: true });
    if (!isEditing) {
      agGridApi.current?.api.stopEditing(true);
    }
  }, [isEditing]);
  // Rerun marginName column valueGetters on filter change
  useEffect(() => {
    agGridApi.current?.api.refreshCells({
      columns: ['marginName'],
    });
  }, [productFilter]);
  const { formatMessage } = useIntl();
  return (
    <TableContainer>
      <AgGridReactWithDefaults
        agGridApiRef={agGridApi}
        columnDefs={getColumnDef(formatMessage, onMarginModified, features)}
        rowData={getRowData(marginPresets, diffs, productFilter ?? [], features)}
        context={context.current}
        groupDefaultExpanded={2}
        groupRemoveSingleChildren={true}
        deltaRowDataMode={true}
        getRowNodeId={getPresetRowNodeId}
      />
    </TableContainer>
  );
};

const HistoryIcon = () => <i className="icon">history</i>;

export const MarginsPresetsTable = connectMarginsPresetsTable(MarginsPresetsTableRaw);
