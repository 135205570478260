import { FormState, defaultFormState } from 'store/utils/form/state';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Client } from 'store/clients';
import { FloatingMargin } from 'store/floatingMargins';

export interface ClientDuplicationForm {
  newClientName: string;
}

export type ClientDuplicationState = FormState<ClientDuplicationForm> & {
  isAlreadyExisting: boolean;
};

const initialState: ClientDuplicationState = {
  ...defaultFormState<ClientDuplicationForm>({
    newClientName: '',
  }),
  isAlreadyExisting: false,
};

export const clientDuplicationSlice = createSlice({
  name: 'clientDuplication',
  initialState,
  reducers: {
    clientNameChanged: (
      state,
      { payload }: PayloadAction<{ clientName: string; alreadyExists: boolean }>,
    ) => {
      state.values.newClientName = payload.clientName;
      state.isAlreadyExisting = payload.alreadyExists;
      state.isReadyToSubmit = payload.clientName !== '' && !payload.alreadyExists;
    },
    duplicationSubmitSent: state => {
      if (!state.isReadyToSubmit) return;
      state.isSubmitting = true;
    },
    duplicationSubmitReceived: (
      state,
      _action: PayloadAction<{ client: Client; floatingMargins: FloatingMargin[] }>,
    ) => {
      state.isSubmitting = false;
    },
    duplicationCleared: () => initialState,
  },
});
