import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors } from 'store';
import { connect } from 'react-redux';

export interface ClientNameConnectOwnProps {}

export interface ClientNameConnectStateProps {
  value: string | undefined;
}

export interface ClientNameConnectDispatchProps {}

// you can whitelist selectors after implementation
export type ClientNameConnectSelectors = Selectors;
/*
export type ClientNameConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ClientNameConnectSelectors = Pick<Selectors, ClientNameConnectSelectorsKeys>;
*/

export const mapStateToPropsClientName: MapStateToPropsHOF<
  ClientNameConnectStateProps,
  ClientNameConnectOwnProps,
  AppState,
  ClientNameConnectSelectors
> = sl => state => ({
  value: sl.getClientFormValue(state, 'name'),
});

// you can whitelist action creators after implementation
export type ClientNameConnectActionCreators = ActionCreators;
/*
export type ClientNameConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type ClientNameConnectActionCreators = Pick<ActionCreators, ClientNameConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsClientName: MapDispatchToPropsHOF<
  ClientNameConnectDispatchProps,
  ClientNameConnectOwnProps,
  ClientNameConnectActionCreators
> = _ac => (_dispatch, _ownProps) => ({});

export const connectClientName = connect(mapStateToPropsClientName(selectors));
