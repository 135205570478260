import React from 'react';
import { connectClientName } from './connect/connectClientName';

export interface ClientNameProps {
  value: string | undefined;
}

const ClientNameRaw: React.FunctionComponent<ClientNameProps> = ({ value }) => (
  <span className="form-control-plaintext text-large font-weight-bold">{value}</span>
);

export const ClientName = connectClientName(ClientNameRaw);
