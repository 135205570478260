import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  GetRowNodeIdFunc,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community';
import {
  leftGroupHeaderClass,
  maturityClass,
  maturityHeaderClass,
  rightBorderClass,
  rightGroupHeaderClass,
  topHeaderClass,
} from 'components/shared/agGrid/constants';
import { IntlFormatters } from 'react-intl';
import { ClientDiff, ClientsDiffs } from 'store/clientEdition/clientEditionSlice';
import { Client } from 'store/clients';
import { Products } from 'store/displayedProducts';
import { FloatingMargin } from 'store/floatingMargins';
import {
  DevlMarginProfile,
  MarginByMaturity,
  MarginProfile,
  SalesMarginProfile,
} from 'store/marginProfiles';
import { pcruMargins } from 'store/pcruProfiles';
import { FeatureToggles } from 'store/userData';
import { areEquivalent } from 'utils/array';
import { range } from 'utils/range';
import { ActionRenderer } from './ActionRenderer';
import { ClientGroupRenderer } from './ClientGroupRenderer';
import { ClientsMarginsTableContext } from './ClientsMarginsTable';

type ConstantMargin = { type: 'pcru' | 'technical'; value: number };
type TotalFloatingMargin = {
  type: 'totalFloating3M' | 'totalFloating6M' | 'totalFloating12M';
  margins: MarginByMaturity[];
};
const editableMarginProfileTypes = ['DevL', 'pcru'] as const;

export interface MarginsTableRow {
  client: Client;
  marginProfile: MarginProfile | TotalFloatingMargin | ConstantMargin;
  diff: ClientDiff;
}

type TypeToMarginProfile<T extends MarginsTableRow['marginProfile']['type']> = T extends 'Sales'
  ? SalesMarginProfile
  : T extends 'DevL'
  ? DevlMarginProfile
  : T extends 'totalFloating3M' | 'totalFloating6M' | 'totalFloating12M'
  ? TotalFloatingMargin
  : T extends 'pcru' | 'technical'
  ? ConstantMargin
  : never;

const fieldPerMarginProfileType = {
  Sales: 'salesMarginProfileId',
  DevL: 'devLMarginProfileId',
  pcru: 'pcruMargin',
} as const;

export const getMarginTableRowNodeId: GetRowNodeIdFunc = (data: MarginsTableRow) => {
  return data.client.id + data.marginProfile.type;
};

// We need something to display while creating a new custom margin
const emptyCustomMargin: SalesMarginProfile = {
  id: -1,
  type: 'Sales',
  name: 'Custom',
  isPreset: false,
  fixedMargins: [],
  floating3M: [],
  floating6M: [],
  floating12M: [],
};

export const getRowData = (
  clients: readonly Client[],
  marginProfiles: Record<number, MarginProfile>,
  floatingMargins: Record<string, FloatingMargin>,
  diffs: ClientsDiffs,
  features: FeatureToggles,
): MarginsTableRow[] =>
  clients.flatMap((client) => {
    const clientData: MarginsTableRow[] = [];
    if (!features.capFlooredActivated) {
      clientData.push(
        {
          client,
          marginProfile: {
            type: 'totalFloating3M',
            margins: floatingMargins[client.name]?.totalFloating3M ?? [],
          },
          diff: diffs[client.id],
        },
        {
          client,
          marginProfile: {
            type: 'totalFloating6M',
            margins: floatingMargins[client.name]?.totalFloating6M ?? [],
          },
          diff: diffs[client.id],
        },
        {
          client,
          marginProfile: {
            type: 'totalFloating12M',
            margins: floatingMargins[client.name]?.totalFloating12M ?? [],
          },
          diff: diffs[client.id],
        },
      );
    }
    clientData.push(
      {
        client,
        marginProfile:
          marginProfiles[diffs[client.id]?.salesMarginProfileId ?? client.salesMarginProfileId] ??
          emptyCustomMargin,
        diff: diffs[client.id],
      },
      {
        client,
        marginProfile:
          marginProfiles[diffs[client.id]?.devLMarginProfileId ?? client.devLMarginProfileId],
        diff: diffs[client.id],
      },
      {
        client,
        marginProfile: { type: 'pcru', value: diffs[client.id]?.pcruMargin ?? client.pcruMargin },
        diff: diffs[client.id],
      },
    );
    if (!features.capFlooredActivated) {
      clientData.push({
        client,
        marginProfile: { type: 'technical', value: 1 },
        diff: diffs[client.id],
      });
    }
    return clientData;
  });

export const getColumnDef = (
  formatMessage: IntlFormatters['formatMessage'],
  marginProfiles: Record<number, MarginProfile>,
  modifyMarginProfile: (clientId: number, diff: Partial<ClientDiff>) => void,
  editClient: (clientId: number) => void,
  duplicateClient: (clientId: number) => void,
  deleteClient: (clientId: number) => void,
  features: FeatureToggles,
): (ColDef | ColGroupDef)[] => [
  {
    headerName: formatMessage({ id: 'clientsMargins.table.client' }),
    headerClass: `${topHeaderClass}  ${leftGroupHeaderClass}`,
    children: [
      {
        headerName: '',
        field: 'marginType',
        headerClass: rightBorderClass,
        showRowGroup: 'client',
        onCellClicked: onClickToggleExpand,
        valueGetter: (params) => {
          if (
            !features.capFlooredActivated &&
            (params.data as MarginsTableRow).marginProfile.type === 'Sales'
          ) {
            return formatMessage({ id: 'clientsMargins.table.marginProfileType.FixedRateOld' });
          }
          return formatMessage({
            id: `clientsMargins.table.marginProfileType.${
              (params.data as MarginsTableRow).marginProfile.type
            }`,
          });
        },
        cellRenderer: 'agGroupCellRenderer',
        pinned: 'left',
        minWidth: 220,
        resizable: true,
        cellClass: rightBorderClass,
        cellClassRules: {
          'text-secondary': (params: CellClassParams) => (params.data as MarginsTableRow) != null,
          [rightBorderClass]: () => true,
        },
        cellRendererParams: {
          suppressCount: true,
          suppressPadding: true,
          suppressDoubleClickExpand: true,
        },
      },
    ],
  },
  {
    headerName: 'Client',
    children: [
      {
        headerName: '',
        field: 'client',
        rowGroup: true,
        hide: true,
        keyCreator: (params: ICellRendererParams) =>
          `${params.value.name}|${params.value.isActive}`,
        cellRendererFramework: (params: ICellRendererParams) =>
          ClientGroupRenderer({
            clientName: params.node.childrenAfterGroup[0].data.client.name,
            isActive: params.node.childrenAfterGroup[0].data.client.isActive,
            formatMessage,
          }),
      },
    ],
  },
  {
    headerName: 'Margin Profile Type',
    children: [{ field: 'marginProfileType', hide: true }],
  },
  {
    headerClass: `${topHeaderClass} ${leftGroupHeaderClass}`,
    headerName: formatMessage({ id: 'clientsMargins.table.marginProfile' }),
    children: [
      {
        headerName: '',
        pinned: 'left',
        headerClass: rightBorderClass,
        field: 'marginProfileName',
        minWidth: 120,
        cellClassRules: {
          'text-secondary': (params: CellClassParams) =>
            (params.data as MarginsTableRow)?.marginProfile.type === 'technical',
          [rightBorderClass]: () => true,
          'font-weight-normal': () => true,
          'px-0': ({ node, context, data }: CellClassParams) =>
            context.isEditing &&
            (node.group ||
              (editableMarginProfileTypes as readonly string[]).includes(data?.marginProfile.type)),
        },
        // We have to use a cell renderer for edit here or it would require a double-click
        // (one for going into edit mode, one for opening the dropdown)
        cellRenderer: (params: ICellRendererParams) => {
          const context = params.context as ClientsMarginsTableContext;
          const data = params.data as MarginsTableRow;

          if (
            !context.isEditing ||
            (!params.node.group &&
              !(editableMarginProfileTypes as readonly string[]).includes(data?.marginProfile.type))
          ) {
            return params.value;
          }

          const dataToEdit: MarginsTableRow = params.node.group
            ? params.node.allLeafChildren.find((c) => c.data.marginProfile.type === 'Sales')?.data
            : data;

          const field =
            fieldPerMarginProfileType[
              dataToEdit.marginProfile.type as typeof editableMarginProfileTypes[number]
            ];
          return getMakeSelectPreset(formatMessage, `select${field}`, (clientId, value) =>
            modifyMarginProfile(clientId, { [field]: Number(value) }),
          )(
            dataToEdit.client.id,
            dataToEdit.diff?.[field] ?? dataToEdit.client[field],
            dataToEdit.marginProfile.type === 'Sales' || dataToEdit.marginProfile.type === 'DevL'
              ? Object.values(marginProfiles)
                  .filter((m) => m.isPreset && m.type === dataToEdit.marginProfile.type)
                  .map((m) => ({ id: m.id, profileName: m.name }))
              : pcruMargins.map((value) => ({ id: value, profileName: `${value}bp` })),
            dataToEdit.marginProfile.type === 'Sales'
              ? dataToEdit.marginProfile.isPreset
                ? -1
                : dataToEdit.marginProfile.id
              : undefined,
          );
        },
        valueGetter: (params) => {
          if (params.node.group) {
            const salesMargin: SalesMarginProfile = params.node.allLeafChildren.find(
              (c) => c.data.marginProfile.type === 'Sales',
            )?.data.marginProfile;
            return salesMargin.isPreset
              ? salesMargin.name
              : formatMessage({ id: 'clientsMargins.table.customMarginProfile' });
          }

          const tableRow: MarginsTableRow = params.data;
          switch (tableRow.marginProfile.type) {
            case 'DevL':
              return tableRow.marginProfile.name;
            case 'pcru':
              return `${tableRow.marginProfile.value}bp`;
            case 'technical':
              return tableRow.marginProfile.value;
            default:
              return '';
          }
        },
        onCellClicked: (clickEvent: CellClickedEvent) => {
          if (!clickEvent.context.isEditing) {
            onClickToggleExpand(clickEvent);
          }
        },
      },
    ],
  },
  {
    headerClass: `${topHeaderClass}`,
    headerName: formatMessage({ id: 'clientsMargins.table.marginByMaturity' }),
    children: range(1, 30).map(
      (maturityIndex): ColDef => ({
        headerName: maturityIndex.toString(),
        colId: getMaturityColId(maturityIndex),
        minWidth: 60,
        headerClass: `${maturityHeaderClass} text-secondary${
          maturityIndex === 30 ? ' border-0' : ''
        }`,
        cellClassRules: {
          [maturityClass]: () => true,
          'text-right': () => true,
          'text-secondary': (params: CellClassParams) =>
            (params.data as MarginsTableRow)?.marginProfile.type === 'pcru' ||
            (params.data as MarginsTableRow)?.marginProfile.type === 'technical',
        },
        valueGetter: (params) => {
          const getMaturityValue = (maturities: MarginByMaturity[]): MarginByMaturity | undefined =>
            maturities.find((margin) => margin.maturity === maturityIndex.toString());

          if (params.node.group) {
            const displayedProducts: Products[] = params.context.displayedProducts;

            if (displayedProducts.length !== 1) {
              return '';
            }

            if (isOutsideClientRange(getClientFromChildren(params.node), maturityIndex)) {
              return '-';
            }

            if (areEquivalent(displayedProducts, ['3M'])) {
              return (
                getMaturityValue(getChildMargin(params.node, 'totalFloating3M').margins)?.value ??
                '-'
              );
            } else if (areEquivalent(displayedProducts, ['6M'])) {
              return (
                getMaturityValue(getChildMargin(params.node, 'totalFloating6M').margins)?.value ??
                '-'
              );
            } else if (areEquivalent(displayedProducts, ['12M'])) {
              return (
                getMaturityValue(getChildMargin(params.node, 'totalFloating12M').margins)?.value ??
                '-'
              );
            } else if (areEquivalent(displayedProducts, ['fixed'])) {
              const margins = [
                getMaturityValue(getChildMargin(params.node, 'Sales').fixedMargins)?.value ?? '-',
                getMaturityValue(getChildMargin(params.node, 'DevL').margins)?.value ?? '-',
                getChildMargin(params.node, 'pcru').value,
                getChildMargin(params.node, 'technical').value,
              ] as const;

              return margins.reduce((prev, curr) => {
                if (curr === '-') {
                  return prev;
                } else if (prev === '-') {
                  return curr;
                } else {
                  return prev + curr;
                }
              }, '-');
            }
          }

          const tableRow: MarginsTableRow = params.data;
          if (isOutsideClientRange(tableRow.client, maturityIndex)) {
            return '-';
          }
          switch (tableRow.marginProfile.type) {
            case 'Sales':
              return getMaturityValue(tableRow.marginProfile.fixedMargins)?.value ?? '-';
            case 'DevL':
              return getMaturityValue(tableRow.marginProfile.margins)?.value ?? '-';
            case 'totalFloating3M':
              return tableRow.marginProfile.margins.length === 0
                ? ''
                : getMaturityValue(tableRow.marginProfile.margins)?.value ?? '-';
            case 'totalFloating6M':
              return tableRow.marginProfile.margins.length === 0
                ? ''
                : getMaturityValue(tableRow.marginProfile.margins)?.value ?? '-';
            case 'totalFloating12M':
              return tableRow.marginProfile.margins.length === 0
                ? ''
                : getMaturityValue(tableRow.marginProfile.margins)?.value ?? '-';
            case 'pcru':
            case 'technical':
              return tableRow.marginProfile.value;
          }
        },
        onCellClicked: onClickToggleExpand,
      }),
    ),
  },
  {
    headerName: formatMessage({ id: 'clientsMargins.table.action' }),
    pinned: 'right',
    headerClass: `${topHeaderClass} ${rightGroupHeaderClass}`,
    children: [
      {
        headerName: '',
        field: 'action',
        minWidth: 80,
        pinned: 'right',
        headerClass: `${rightGroupHeaderClass}`,
        cellClass: `${rightGroupHeaderClass} actions-button-cell`,
        cellStyle: { left: '1px' },
        cellRendererFramework: (params: ICellRendererParams) =>
          params.node.group &&
          ActionRenderer({
            clientId: params.node.childrenAfterGroup[0].data.client.id,
            onEdit: editClient,
            onDuplicate: duplicateClient,
            onDelete: deleteClient,
            formatMessage,
          }),
      },
    ],
  },
];

const onClickToggleExpand = (e: CellClickedEvent) => {
  if (e.node.isExpandable()) {
    e.node.setExpanded(!e.node.expanded);
  }
};

const getMakeSelectPreset = (
  formatMessage: IntlFormatters['formatMessage'],
  functionName: string,
  onChange: (clientId: number, value: string) => void,
) => {
  if (registeredFunctions[functionName]) {
    return registeredFunctions[functionName];
  }

  (window as any)[functionName] = onChange;

  registeredFunctions[functionName] = (
    clientId: number,
    currentValue: number,
    margins: readonly { id: number; profileName: string }[],
    customProfileId: number | undefined,
  ) => `<select class='form-control form-control-alt h-100 py-0 border-0' onchange='${functionName}(${clientId}, this.value)'>
      ${margins
        .map(
          (margin) =>
            `<option value="${margin.id}"${margin.id === currentValue ? ' selected' : ''}>${
              margin.profileName
            }</option>`,
        )
        .join('')}
        ${
          customProfileId !== undefined &&
          `<option value="${customProfileId}"${customProfileId === currentValue ? ' selected' : ''}>
            Custom
          </option>`
        }
        </select>`;

  return registeredFunctions[functionName];
};

const registeredFunctions: Record<string, Function> = {};

const getClientFromChildren = (node: RowNode) =>
  (node.allLeafChildren.find((c) => c.data !== null)?.data as MarginsTableRow).client;

const getChildMargin = <T extends MarginsTableRow['marginProfile']['type']>(
  node: RowNode,
  marginType: T,
) => {
  const data = node.allLeafChildren.find((c) => c.data.marginProfile.type === marginType)!
    .data as MarginsTableRow;
  return data.marginProfile as TypeToMarginProfile<T>;
};

const isOutsideClientRange = (client: Client, maturityIndex: number) =>
  client.lowerMaturityRange > maturityIndex || client.upperMaturityRange < maturityIndex;

export const getMaturityColId = (index: number) => `maturity_${index}`;
