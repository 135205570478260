import React, { useState, useEffect, useCallback } from 'react';

export type LazyInputProps = Omit<JSX.IntrinsicElements['input'], 'value' | 'onChange'> & {
  value: string;
  onChange(value: string): void;
};

export const LazyInput = React.forwardRef<HTMLInputElement, LazyInputProps>(
  ({ value, onChange, ...inputProps }, ref) => {
    const [currentValue, setCurrentValue] = useState(value);
    useEffect(() => setCurrentValue(value), [value, setCurrentValue]);
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (ev) => setCurrentValue(ev.target.value),
      [setCurrentValue],
    );
    const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (_ev) => onChange(currentValue),
      [onChange, currentValue],
    );
    return (
      <input
        ref={ref}
        type="text"
        className={`form-control ${inputProps.className ? inputProps.className : ''}`}
        value={currentValue}
        onChange={handleChange}
        onBlur={handleBlur}
        {...inputProps}
      />
    );
  },
);
