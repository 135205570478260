import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, actionCreators, selectors } from 'store';
import { Products } from 'store/displayedProducts';
import { connect } from 'react-redux';

interface ProductFilterConnectOwnProps {}

interface ProductFilterConnectStateProps {
  displayedProducts: Products[];
}

interface ProductFilterConnectDispatchProps {
  onSelectProduct: (products: Products[]) => void;
}

type ProductFilterConnectSelectors = Selectors;

const mapStateToPropsProductFilter: MapStateToPropsHOF<
  ProductFilterConnectStateProps,
  ProductFilterConnectOwnProps,
  AppState,
  ProductFilterConnectSelectors
> = sl => state => ({
  displayedProducts: sl.getDisplayedProducts(state),
});

type ProductFilterConnectActionCreators = ActionCreators;

const mapDispatchToPropsProductFilter: MapDispatchToPropsHOF<
  ProductFilterConnectDispatchProps,
  ProductFilterConnectOwnProps,
  ProductFilterConnectActionCreators
> = ac => dispatch => ({
  onSelectProduct: (products: Products[]) =>
    dispatch(ac.displayedProducts.displayedProductsChanged(products)),
});

export const connectProductFilter = connect(
  mapStateToPropsProductFilter(selectors),
  mapDispatchToPropsProductFilter(actionCreators),
);
