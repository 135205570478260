import React from 'react';
import { useIntl } from 'react-intl';
import { ProfileFilter } from '../Filter/index';
import { FilterGroup } from './FilterGroup';
import { connectSalesFilters } from './connect/connectSalesFilters';

export interface SalesProfileFiltersProps {
  ids: number[];
  isVisible: boolean;
}

export const SalesProfileFiltersRaw: React.FunctionComponent<SalesProfileFiltersProps> = ({
  ids,
  isVisible,
}) => {
  const { formatMessage } = useIntl();
  return isVisible ? (
    <FilterGroup label={formatMessage({ id: 'filters.salesMargin' })}>
      {ids.map(id => (
        <ProfileFilter key={id} marginProfileId={id} type={'Sales'} />
      ))}
    </FilterGroup>
  ) : null;
};

export const SalesProfileFilters = connectSalesFilters(SalesProfileFiltersRaw);
