import React from 'react';
import { useIntl } from 'react-intl';
import { PcruFilter } from '../Filter';
import { FilterGroup } from './FilterGroup';

export const PcruFilters: React.FunctionComponent = () => {
  const { formatMessage } = useIntl();
  return (
    <FilterGroup label={formatMessage({ id: 'filters.pcruMargin' })}>
      <PcruFilter value={5} />
      <PcruFilter value={10} />
    </FilterGroup>
  );
};
