import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { IntlFormatters } from 'react-intl';

export interface ClientGroupRendererProps {
  clientName: string;
  isActive: boolean;
  formatMessage: IntlFormatters['formatMessage'];
}

export const ClientGroupRenderer: React.FunctionComponent<ClientGroupRendererProps> = ({
  clientName,
  isActive,
  formatMessage,
}) => {
  const [ref, setRef] = React.useState<HTMLElement | null>(null);
  return isActive ? (
    <span title={clientName}>{clientName}</span>
  ) : (
    <>
      <span
        className="text-secondary ag-cell pl-0"
        ref={setRef}
        title={clientName}
        style={{ maxWidth: '75%' }}
      >
        {clientName}
        <i className="icon ml-1 text-secondary" style={{ fontSize: '8px' }}>
          lens
        </i>
      </span>
      {ref && (
        <UncontrolledTooltip placement="right" target={ref}>
          {formatMessage({ id: 'clientsMargins.inactiveClient' })}
        </UncontrolledTooltip>
      )}
    </>
  );
};
