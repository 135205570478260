import { Fetch } from './utils';

const featureToggles = [
  'canEditClientMargins',
  'canEditMaturities',
  'iamAuthorizationActivated',
  'capFlooredActivated',
] as const;

export interface UserDataApi {
  getUserData(): Promise<BackendUserData>;
}

type FeatureToggleKeys = typeof featureToggles[number];
type FeatureToggles = Record<FeatureToggleKeys, boolean>;

export interface BackendUserData {
  featureToggles: FeatureToggles;
}

export const userDataApi = (fetch: Fetch): UserDataApi => ({
  getUserData: async () => {
    const res = await fetch('/api/userData');
    return await res.json();
  },
});
