import { UserDataApi, userDataApi } from './userData';
import { Fetch, fetch } from './utils';
import { ClientsApi, clientsApi } from './clients';
import { MarginProfilesApi, marginProfileApi } from './marginProfiles';
import { ClientFloatingMarginsApi, clientFloatingMarginsApi } from './clientFloatingMargins';

export interface WmcApi {
  clients: ClientsApi;
  floatingMargins: ClientFloatingMarginsApi;
  marginProfiles: MarginProfilesApi;
  userData: UserDataApi;
}

export const createWmcApi = (fetchFn?: Fetch): WmcApi => {
  const fetchImpl = fetchFn ?? fetch;
  return {
    clients: clientsApi(fetchImpl),
    floatingMargins: clientFloatingMarginsApi(fetchImpl),
    marginProfiles: marginProfileApi(fetchImpl),
    userData: userDataApi(fetchImpl),
  };
};
