import React, { useCallback } from 'react';
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { IntlFormatters } from 'react-intl';

export type ActionRendererProps = {
  clientId: number;
  onEdit(clientId: number): void;
  onDuplicate(clientId: number): void;
  onDelete(clientId: number): void;
  formatMessage: IntlFormatters['formatMessage'];
};

export const ActionRenderer: React.FunctionComponent<ActionRendererProps> = ({
  clientId,
  onEdit,
  onDuplicate,
  onDelete,
  formatMessage,
}) => {
  const actionHandler = useCallback(
    (action: (clientId: number) => void) => () => action(clientId),
    [clientId],
  );
  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="button" className="btn btn-sm btn-flat-secondary">
        <i className="icon">more_vert</i>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={actionHandler(onEdit)}>
          {formatMessage({ id: 'clientsMargins.actions.edit' })}
        </DropdownItem>
        <DropdownItem>{formatMessage({ id: 'clientsMargins.actions.viewHistory' })}</DropdownItem>
        <DropdownItem onClick={actionHandler(onDuplicate)}>
          {formatMessage({ id: 'clientsMargins.actions.duplicate' })}
        </DropdownItem>
        <DropdownItem onClick={actionHandler(onDelete)}>
          {formatMessage({ id: 'clientsMargins.actions.delete' })}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
