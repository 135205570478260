import { MarginProfileType } from 'store/marginProfiles';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { Selectors } from 'store';
import { ActionCreators } from 'store';
import { AppState } from 'store';

export interface ProfileFilterConnectOwnProps {
  type: MarginProfileType;
  marginProfileId: number;
}

export interface ProfileFilterConnectStateProps {
  label: string | undefined;
  active: boolean;
}

export interface ProfileFilterConnectDispatchProps {
  toggle: () => void;
}

// you can whitelist selectors after implementation
export type ProfileFilterConnectSelectors = Selectors;
/*
export type ProfileFilterConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ProfileFilterConnectSelectors = Pick<Selectors, ProfileFilterConnectSelectorsKeys>;
*/

export const mapStateToPropsProfileFilter: MapStateToPropsHOF<
  ProfileFilterConnectStateProps,
  ProfileFilterConnectOwnProps,
  AppState,
  ProfileFilterConnectSelectors
> = sl => (state, { type, marginProfileId }) => ({
  label: sl.getMarginsPresets(state).find(profile => profile.id === marginProfileId)?.name,
  active:
    type === 'Sales'
      ? sl.getFilter(state, 'salesProfiles').includes(marginProfileId)
      : sl.getFilter(state, 'devLProfiles').includes(marginProfileId),
});

// you can whitelist action creators after implementation
export type ProfileFilterConnectActionCreators = ActionCreators;
/*
export type ProfileFilterConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type ProfileFilterConnectActionCreators = Pick<ActionCreators, ProfileFilterConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsProfileFilter: MapDispatchToPropsHOF<
  ProfileFilterConnectDispatchProps,
  ProfileFilterConnectOwnProps,
  ProfileFilterConnectActionCreators
> = ac => (dispatch, { type, marginProfileId }) => ({
  toggle: () =>
    type === 'Sales'
      ? dispatch(ac.filters.salesProfileFilterToggled(marginProfileId))
      : dispatch(ac.filters.devLProfileFilterToggled(marginProfileId)),
});
