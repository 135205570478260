import React from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-mini-footer': React.ClassAttributes<Element> & SGWTMiniFooterAttributes;
    }
  }
}

interface SGWTMiniFooterAttributes {
  id: string;
  mode: 'sg-markets';
  'contact-us': string;
}

export const Footer: React.FunctionComponent = () => (
  <sgwt-mini-footer
    id="sgwt-mini-footer"
    mode="sg-markets"
    contact-us="{'mail':'sgmarketsfx@sgcib.com'}"
  />
);
