import React from 'react';
import { Page } from 'store/ui';
import { connectPageSwitch } from './connect/connectPageSwitch';

export interface PageSwitchProps {
  currentPage: Page;
  pages: Record<Page, React.ReactNode>;
}

const PageSwitchRaw: React.FunctionComponent<PageSwitchProps> = ({ currentPage, pages }) => (
  <>{pages[currentPage]}</>
);

export const PageSwitch = connectPageSwitch(PageSwitchRaw);
