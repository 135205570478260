import { createSelector } from '@reduxjs/toolkit';
import { MapStateToPropsFactoryHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';
import { MarginProfileType } from 'store/marginProfiles';
import { connect } from 'react-redux';
import { ClientForm } from 'store/clientForm/clientFormSlice';

export interface SelectMarginProfileConnectOwnProps {}

export interface SelectMarginProfileConnectStateProps {
  value: number | undefined;
  options: readonly [number, string][];
}

export interface SelectMarginProfileConnectDispatchProps {
  onChange(value: number): void;
}

// you can whitelist selectors after implementation
export type SelectMarginProfileConnectSelectors = Selectors;
/*
export type SelectMarginProfileConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type SelectMarginProfileConnectSelectors = Pick<Selectors, SelectMarginProfileConnectSelectorsKeys>;
*/

const marginProfileTypeToField: Record<
  Exclude<MarginProfileType, 'NA'>,
  keyof Pick<ClientForm, 'salesMarginProfileId' | 'devLMarginProfileId'>
> = {
  Sales: 'salesMarginProfileId',
  DevL: 'devLMarginProfileId',
};

export const mapStateToPropsSelectMarginProfile: (
  type: Exclude<MarginProfileType, 'NA'>,
) => MapStateToPropsFactoryHOF<
  SelectMarginProfileConnectStateProps,
  SelectMarginProfileConnectOwnProps,
  AppState,
  SelectMarginProfileConnectSelectors
> = (type) => (sl) => () =>
  createSelector(
    (state) => sl.getClientFormValue(state, marginProfileTypeToField[type]),
    (state) => {
      const marginProfileId = sl.getClientFormValue(state, marginProfileTypeToField[type]);
      return marginProfileId !== undefined && sl.isCustomProfile(state, marginProfileId);
    },
    sl.getMarginsPresets,
    (value, isAlreadyCustomized, marginsPresets) => ({
      value,
      options: [
        ...marginsPresets
          .filter((x) => x.type === type)
          .map((x) => [x.id, x.name] as [number, string]),
        ...(type === 'Sales'
          ? [[isAlreadyCustomized ? value : -1, 'Custom'] as [number, string]]
          : []),
      ],
    }),
  );

export type SelectMarginProfileConnectActionCreators = ActionCreators;

export const mapDispatchToPropsSelectMarginProfile: (
  type: Exclude<MarginProfileType, 'NA'>,
) => MapDispatchToPropsHOF<
  SelectMarginProfileConnectDispatchProps,
  SelectMarginProfileConnectOwnProps,
  SelectMarginProfileConnectActionCreators
> = (type) => (ac) => (dispatch) => ({
  onChange(value: number) {
    dispatch(
      ac.clientForm.clientFormFieldChanged({
        [marginProfileTypeToField[type]]: value,
      }),
    );
  },
});

export const connectSelectMarginProfile = (type: Exclude<MarginProfileType, 'NA'>) =>
  connect(
    mapStateToPropsSelectMarginProfile(type)(selectors),
    mapDispatchToPropsSelectMarginProfile(type)(actionCreators),
  );
