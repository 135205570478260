import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getMatomo } from 'bootstrap/matomo';
import { useToggle } from 'components/hooks/useToggle';
import { connectCrashModal } from './connect/connectCrashModal';
import { LoadingButton } from 'components/shared/LoadingButton';

export interface CrashModalProps {
  error: string | null;
}

const CrashModalRaw: React.FunctionComponent<CrashModalProps> = ({ error }) => {
  const [isDetailsOpen, toggleDetails] = useToggle();
  const [isLoading, setLoading] = useState(false);
  const matomo = getMatomo();
  useEffect(() => {
    if (error != null) {
      matomo.track('Crash modal', 'Opened');
    }
  }, [error, matomo]);
  const reload = () => {
    setLoading(true);
    matomo.track('Crash modal', 'Reload clicked');
    window.location.reload();
  };
  return error ? (
    <Modal isOpen className="border border-danger">
      <ModalHeader tag="h3" className="text-danger">
        <FormattedMessage id="crashModal.title" />
      </ModalHeader>
      <ModalBody>
        <p>
          <FormattedMessage id="crashModal.description" />
        </p>
        <div className="sgbs-collapse-separator sgbs-collapse-separator-centered">
          <div className="card bg-transparent">
            <div className="card-header p-0">
              <button
                type="button"
                className={`btn btn-flat-primary btn-block${isDetailsOpen ? '' : ' collapsed'}`}
                data-toggle="collapse"
                onClick={toggleDetails}
              >
                <span>
                  <FormattedMessage id="crashModal.technicalDetails" />
                  <i className="icon icon-sm">keyboard_arrow_down</i>
                </span>
              </button>
              <Collapse isOpen={isDetailsOpen}>
                <pre>{error}</pre>
              </Collapse>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <LoadingButton className="btn btn-lg btn-danger" loading={isLoading} onClick={reload}>
          <FormattedMessage id="common.reload" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  ) : null;
};

export const CrashModal = connectCrashModal(CrashModalRaw);
