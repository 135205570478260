import { PayloadAction, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { clientFormSlice } from 'store/clientForm/clientFormSlice';
import { clientEditionSlice } from 'store/clientEdition';
import { clientDuplicationSlice } from 'store/clientDuplication/clientDuplicationSlice';
import {
  FetchableState,
  defaultFetchableState,
  createFetchableReducers,
} from 'store/utils/fetchable';

export interface Client {
  id: number;
  name: string;
  salesMarginProfileId: number;
  salesMarginProfileName: string;
  devLMarginProfileId: number;
  devLMarginProfileName: string;
  pcruMargin: number;
  lowerMaturityRange: number;
  upperMaturityRange: number;
  isActive: boolean;
}

export const minimumLowerMaturityRange = 1;
export const maximumUpperMaturityRange = 30;

export const changeReasons = [
  'Client feedback',
  'Price being not competitive enough vs other banks',
  'Change of guarantees',
  'Change of status',
  'Business review',
] as const;
export type ChangeReason = typeof changeReasons[number];

export const clientsAdapter = createEntityAdapter<Client>();

type ClientsState = EntityState<Client> & FetchableState;

const initialState: ClientsState = {
  ...clientsAdapter.getInitialState(),
  ...defaultFetchableState,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    ...createFetchableReducers<ClientsState, Client>({
      fetched: clientsAdapter.addMany,
    }),
    clientDeactivated: (state, { payload: client }: PayloadAction<Client>) =>
      clientsAdapter.updateOne(state, {
        id: client.id,
        changes: client,
      }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(clientFormSlice.actions.clientFormSubmitReceived, (state, { payload }) =>
        clientsAdapter.upsertOne(state, payload.client),
      )
      .addCase(clientEditionSlice.actions.editionSaved, (state, { payload }) =>
        clientsAdapter.upsertMany(state, payload.clients),
      )
      .addCase(clientDuplicationSlice.actions.duplicationSubmitReceived, (state, { payload }) =>
        clientsAdapter.upsertOne(state, payload.client),
      ),
});
