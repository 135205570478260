import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { LazyInput } from 'components/shared/Form/LazyInput';
import { FilterGroup } from '../FilterGroups/FilterGroup';
import { connectMaturitiesFilter } from './connectMaturitiesFilter';
import { range } from 'utils/range';

export interface MaturitiesFilterProps {
  lowerBound: number;
  upperBound: number;
  onLowerBoundChange(value: number): void;
  onUpperBoundChange(value: number): void;
}

const StyledInput = styled(LazyInput).attrs(() => ({
  className: 'form-control text-center',
}))`
  width: 3.5rem;
`;

export const MaturitiesFilterRaw: React.FunctionComponent<MaturitiesFilterProps> = ({
  lowerBound,
  upperBound,
  onLowerBoundChange,
  onUpperBoundChange,
}) => {
  const { formatMessage } = useIntl();
  const filterInput: React.KeyboardEventHandler<HTMLInputElement> = useCallback((ev) => {
    const numKeys = range(0, 9).map((n) => n.toString());
    if (!numKeys.includes(ev.key)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }, []);
  return (
    <FilterGroup label={formatMessage({ id: 'filters.maturities' })}>
      <StyledInput
        value={lowerBound.toString()}
        onChange={(value) => onLowerBoundChange(Number(value))}
        onKeyPress={filterInput}
      />
      <span className="mx-2">-</span>
      <StyledInput
        value={upperBound.toString()}
        onChange={(value) => onUpperBoundChange(Number(value))}
        onKeyPress={filterInput}
      />
    </FilterGroup>
  );
};

export const MaturitiesFilter = connectMaturitiesFilter(MaturitiesFilterRaw);
