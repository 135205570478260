export type FormState<T> = {
  values: T;
  errors: FormErrors<T>;
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
};

export type FormErrors<T> = {
  [K in keyof T]?: FormError;
};

export interface FormError {
  code: string;
  userNotified: boolean;
}

export const defaultFormState = <T extends {}>(formValues: T): FormState<T> => ({
  values: formValues,
  errors: {},
  isReadyToSubmit: false,
  isSubmitting: false,
});
