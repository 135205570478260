import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { store } from 'bootstrap/store';
import { ClientsMargins } from 'components/ClientsMargins';
import { MarginsPresets } from 'components/MarginsPresets/MarginsPresets';
import { HelpCenter } from './HelpCenter';
import { Header } from './Header';
import { Footer } from './Footer';
import { PageSwitch } from './PageSwitch';
import { CrashModal } from './CrashModal';
import { TimeoutModal } from './TimeoutModal';

import './app.css';
import messages from 'locales/en.json';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <IntlProvider locale="en" messages={messages}>
        <React.StrictMode>
          <CrashModal />
          <TimeoutModal />
          <HelpCenter />
          <div className="d-flex flex-column vh-100 container-fluid p-0">
            <Header />
            <div className="flex-grow-1 d-flex flex-column">
              <PageSwitch
                pages={{
                  ClientsMargins: <ClientsMargins />,
                  MarginsPresets: <MarginsPresets />,
                }}
              />
            </div>
            <Footer />
          </div>
        </React.StrictMode>
      </IntlProvider>
    </Provider>
  );
};

export default App;
