import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { connect } from 'react-redux';
import { MarginProfile } from 'store/marginProfiles';
import { AppState, Selectors, selectors, ActionCreators, actionCreators } from 'store';
import { MarginProfileDiffs } from 'store/marginProfilesEdition/marginProfilesEditionSlice';

export interface MarginsPresetsTableStateProps {
  marginPresets: readonly MarginProfile[];
  isEditing: boolean;
  diffs: MarginProfileDiffs;
}

export interface MarginPresetsTableDispatchProps {
  onMarginModified(marginId: string, value: number): void;
}

const mapStateToProps: MapStateToPropsHOF<
  MarginsPresetsTableStateProps,
  {},
  AppState,
  Selectors
> = (sl) => (state) => ({
  marginPresets: sl.getAllMarginProfiles(state),
  isEditing: sl.isEditingMarginProfiles(state),
  diffs: sl.getMarginProfileDiff(state),
});

const mapDispatchToProps: MapDispatchToPropsHOF<
  MarginPresetsTableDispatchProps,
  {},
  ActionCreators
> = (ac) => (dispatch) => ({
  onMarginModified: (marginId, value) =>
    dispatch(ac.marginProfileEdition.modifyMargin(marginId, value)),
});

export const connectMarginsPresetsTable = connect(
  mapStateToProps(selectors),
  mapDispatchToProps(actionCreators),
);
