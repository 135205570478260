import { connect } from 'react-redux';
import { MapStateToPropsHOF } from 'typings/redux-connect';
import { Selectors, selectors } from 'store';
import { AppState } from 'store';

export interface ProfileFiltersConnectOwnProps {}

export interface ProfileFiltersConnectStateProps {
  ids: number[];
}

// you can whitelist selectors after implementation
export type ProfileFiltersConnectSelectors = Selectors;
/*
export type ProfileFiltersConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ProfileFiltersConnectSelectors = Pick<Selectors, ProfileFiltersConnectSelectorsKeys>;
*/

export const mapStateToPropsProfileFilters: MapStateToPropsHOF<
  ProfileFiltersConnectStateProps,
  ProfileFiltersConnectOwnProps,
  AppState,
  ProfileFiltersConnectSelectors
> = sl => state => ({
  ids: sl.getDevLProfileIds(state),
});

export const connectDevLFilters = connect(mapStateToPropsProfileFilters(selectors));
