import styled from 'styled-components';
import {
  maturityHeaderClass,
  leftGroupHeaderClass,
  rightGroupHeaderClass,
  maturityClass,
} from './constants';

export const TableContainer = styled.div.attrs((props) => ({
  className: 'ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed w-100 h-100',
}))`
  & .${maturityHeaderClass} .ag-cell-label-container {
    float: right;
  }

  & .${leftGroupHeaderClass} {
    border-right: 1px solid var(--primary) !important;
  }

  & .${rightGroupHeaderClass} {
    border-left: 1px solid var(--primary) !important;
    border-right: 1px solid #e2e2e2 !important;
  }

  & .${maturityClass}.ag-column-hover {
    background-color: rgba(110, 119, 122, 0.2);
  }
`;
