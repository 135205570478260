import { MapStateToPropsHOF } from 'typings/redux-connect';
import { Selectors, selectors } from 'store';
import { AppState } from 'store';
import { connect } from 'react-redux';

export interface ProfileFiltersConnectOwnProps {}

export interface ProfileFiltersConnectStateProps {
  ids: number[];
  isVisible: boolean;
}

// you can whitelist selectors after implementation
export type ProfileFiltersConnectSelectors = Selectors;
/*
export type ProfileFiltersConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ProfileFiltersConnectSelectors = Pick<Selectors, ProfileFiltersConnectSelectorsKeys>;
*/

export const mapStateToPropsProfileFilters: MapStateToPropsHOF<
  ProfileFiltersConnectStateProps,
  ProfileFiltersConnectOwnProps,
  AppState,
  ProfileFiltersConnectSelectors
> = sl => state => ({
  ids: sl.getSalesProfileIds(state),
  isVisible: !sl.getFilters(state).customizedMarginsOnly,
});

export const connectSalesFilters = connect(mapStateToPropsProfileFilters(selectors));
