import { AppThunk } from 'store';

export const fetchFloatingMargins = (): AppThunk<Promise<void>> => async (
  dispatch,
  _getState,
  { api, actionCreators },
) => {
  dispatch(actionCreators.floatingMargins.fetch());
  const floatingMargins = await api.floatingMargins.getClientFloatingMargins();
  dispatch(actionCreators.floatingMargins.fetched(floatingMargins));
};
