import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { LazyInput } from 'components/shared/Form/LazyInput';
import { range } from 'utils/range';
import { connectMaturities } from './connect/connectMaturities';

export interface MaturitiesProps {
  lowerBound: number;
  upperBound: number;
  onLowerBoundChanged(value: number): void;
  onUpperBoundChanged(value: number): void;
}

export const MaturitiesRaw: React.FunctionComponent<MaturitiesProps> = ({
  lowerBound,
  upperBound,
  onLowerBoundChanged,
  onUpperBoundChanged,
}) => {
  const filterInput: React.KeyboardEventHandler<HTMLInputElement> = useCallback((ev) => {
    const numKeys = range(0, 9).map((n) => n.toString());
    if (!numKeys.includes(ev.key)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }, []);
  return (
    <div className="form-group d-flex mb-0">
      <label className="col-form-label mr-2">
        <FormattedMessage id="clientForm.maturities.from" />
      </label>
      <div>
        <LazyInput
          type="text"
          value={lowerBound.toString()}
          onChange={(val) => onLowerBoundChanged(Number(val))}
          onKeyPress={filterInput}
        />
      </div>
      <label className="col-form-label mx-2">
        <FormattedMessage id="clientForm.maturities.to" />
      </label>
      <div>
        <LazyInput
          type="text"
          value={upperBound.toString()}
          onChange={(val) => onUpperBoundChanged(Number(val))}
          onKeyPress={filterInput}
        />
      </div>
    </div>
  );
};

export const Maturities = connectMaturities(MaturitiesRaw);
