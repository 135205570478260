import { sgwtConnect } from 'bootstrap/sgwtConnect';
import { appConfig } from 'config';
import { v4 } from 'uuid';

export type Fetch = typeof window['fetch'];

const apiUrl = appConfig.apiUrl.replace(/\/$/, '');

export const fetch: Fetch = (input, init) => {
  const routeUrl = typeof input === 'string' ? input : input.url;
  const authorizationHeader = sgwtConnect.getAuthorizationHeader();
  const requestInit: RequestInit = {
    headers: {
      Accept: 'application/json',
      ...(authorizationHeader !== null ? { Authorization: authorizationHeader } : {}),
      'Content-Type': 'application/json',
      'X-Correlation-ID': v4(),
      ...init?.headers,
    },
    ...init,
  };
  return window.fetch(apiUrl + routeUrl, requestInit);
};

export const ensureSuccessStatusCode = (response: Response) => {
  if (response.status === 403) {
    window.location.replace(appConfig.sgConnect_unauthorize_redirect_uri + window.location.href);
    return;
  }

  if (!response.ok) {
    throw new Error(`Network response was not ok (${response.status} ${response.statusText})`);
  }
};
