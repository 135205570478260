import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { narrowDefined } from 'utils/asserts';
import { MarginProfile, getMargins, marginProfilesAdapter } from './marginProfilesSlice';

const getMarginProfilesState = (state: AppState) => state.marginProfiles;

const marginProfilesSelectors = marginProfilesAdapter.getSelectors(getMarginProfilesState);

export const isMarginProfilesFetching = (state: AppState) =>
  getMarginProfilesState(state).isFetching;

export const getAllMarginProfiles = createSelector(
  marginProfilesSelectors.selectIds,
  marginProfilesSelectors.selectEntities,
  // Relies on IDs to keep original insertion order (JS objects do *not* preserve it)
  (ids, items) => ids.map((id) => items[id]!),
);

export const getMarginProfiles = marginProfilesSelectors.selectEntities;

export const getMarginProfileById: (
  state: AppState,
  marginProfileId: number,
) => MarginProfile | undefined = marginProfilesSelectors.selectById;

export const getMarginProfileValue = (
  state: AppState,
  marginProfileId: number,
  maturity: number,
) => {
  const profile = getMarginProfileById(state, marginProfileId);
  return profile && getMargins(profile).find((x) => x.maturity === maturity.toString())?.value;
};

export const getMarginsPresets = createSelector(getAllMarginProfiles, (marginProfiles) =>
  marginProfiles.filter((x) => narrowDefined(x).isPreset),
);

export const getSalesProfileIds = createSelector(getMarginsPresets, (marginProfiles) =>
  marginProfiles
    .filter((profile) => profile?.type === 'Sales')
    .map((profile) => narrowDefined(profile).id),
);

export const getDevLProfileIds = createSelector(getMarginsPresets, (marginProfiles) =>
  marginProfiles
    .filter((profile) => profile?.type === 'DevL')
    .map((profile) => narrowDefined(profile).id),
);

export const isCustomProfile = (state: AppState, marginProfileId: number) =>
  !getMarginProfileById(state, marginProfileId)?.isPreset;
