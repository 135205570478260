import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';
import { connect } from 'react-redux';

export interface MaturitiesConnectOwnProps {}

export interface MaturitiesConnectStateProps {
  lowerBound: number;
  upperBound: number;
}

export interface MaturitiesConnectDispatchProps {
  onLowerBoundChanged(value: number): void;
  onUpperBoundChanged(value: number): void;
}

// you can whitelist selectors after implementation
export type MaturitiesConnectSelectors = Selectors;
/*
export type MaturitiesConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type MaturitiesConnectSelectors = Pick<Selectors, MaturitiesConnectSelectorsKeys>;
*/

export const mapStateToPropsMaturities: MapStateToPropsHOF<
  MaturitiesConnectStateProps,
  MaturitiesConnectOwnProps,
  AppState,
  MaturitiesConnectSelectors
> = (sl) => (state) => ({
  lowerBound: sl.getClientFormValue(state, 'lowerMaturityRange'),
  upperBound: sl.getClientFormValue(state, 'upperMaturityRange'),
});

// you can whitelist action creators after implementation
export type MaturitiesConnectActionCreators = ActionCreators;
/*
export type MaturitiesConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type MaturitiesConnectActionCreators = Pick<ActionCreators, MaturitiesConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsMaturities: MapDispatchToPropsHOF<
  MaturitiesConnectDispatchProps,
  MaturitiesConnectOwnProps,
  MaturitiesConnectActionCreators
> = (ac) => (dispatch) => ({
  onLowerBoundChanged: (value) =>
    dispatch(
      ac.clientForm.clientFormFieldChanged({
        lowerMaturityRange: value,
      }),
    ),
  onUpperBoundChanged: (value) =>
    dispatch(
      ac.clientForm.clientFormFieldChanged({
        upperMaturityRange: value,
      }),
    ),
});

export const connectMaturities = connect(
  mapStateToPropsMaturities(selectors),
  mapDispatchToPropsMaturities(actionCreators),
);
