import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { connectClientSearch } from './connectClientSearch';

export interface ClientSearchProps {
  clientName: string;
  onClientNameChange(value: string): void;
}

const ClientSearchRaw: React.FunctionComponent<ClientSearchProps> = ({
  clientName,
  onClientNameChange,
}) => {
  const { formatMessage } = useIntl();
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => onClientNameChange(event.currentTarget.value),
    [onClientNameChange],
  );
  return (
    <div className="form-group mb-0">
      <div className="input-group input-group-xl">
        <div className="input-group-prepend input-group-merged">
          <i className="icon icon-md text-secondary">search</i>
        </div>
        <input
          type="search"
          className="form-control from-control-xl"
          placeholder={formatMessage({ id: 'filters.searchClient' })}
          value={clientName}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export const ClientSearch = connectClientSearch(ClientSearchRaw);
