import React from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { agThemeSgBootstrapCondensed } from '@sg-bootstrap/ag-grid';
import { GridApi, GridReadyEvent, GridSizeChangedEvent, ColumnApi } from 'ag-grid-community';

import 'ag-grid-community/dist/styles/ag-grid.css';

export type AgGridReactWithDefaultsProps = {
  agGridApiRef: React.MutableRefObject<{ api: GridApi; columnApi: ColumnApi } | undefined>;
} & AgGridReactProps;

export const AgGridReactWithDefaults: React.FunctionComponent<AgGridReactWithDefaultsProps> = ({
  agGridApiRef,
  ...props
}) => {
  // Provides access to the grid API from the outside
  const onGridReady = React.useCallback(
    ({ api, columnApi }: GridReadyEvent) => {
      agGridApiRef.current = { api, columnApi };
      api.sizeColumnsToFit();
    },
    [agGridApiRef],
  );

  // Fill horizontal space automatically
  const gridWidth = React.useRef(0);
  const onGridSizeChanged = React.useCallback(
    ({ api, clientWidth }: GridSizeChangedEvent) => {
      if (gridWidth.current !== clientWidth) {
        gridWidth.current = clientWidth;
        api.sizeColumnsToFit();
      }
    },
    [gridWidth],
  );

  return (
    <AgGridReact
      gridOptions={{ ...agThemeSgBootstrapCondensed }} // AgGrid seems to mutate its options, so we need unique instances.
      defaultColDef={{ menuTabs: [], enableRowGroup: true }}
      suppressAggFuncInHeader={true}
      suppressMovableColumns={true}
      groupSuppressAutoColumn={true}
      enableRangeSelection={true}
      onGridReady={onGridReady}
      onGridSizeChanged={onGridSizeChanged}
      {...props}
    />
  );
};
