import { AppThunk } from 'store';
import { strictEntries } from 'utils/object';
import { ClientDiff } from './clientEditionSlice';
import { CustomMarginProfileId } from 'store/clientForm';
import { narrowDefined } from 'utils/asserts';

export const modifyMarginProfile = (clientId: number, diff: Partial<ClientDiff>): AppThunk => (
  dispatch,
  getState,
  { selectors: sl, actionCreators: ac },
) => {
  const state = getState();
  strictEntries(diff).forEach(([key, value]) => {
    // If the value was changed, and changed again to its original value
    if (key !== 'changeReason' && value === narrowDefined(sl.getClientById(state, clientId))[key]) {
      dispatch(ac.clientEdition.marginProfileResetted({ clientId, diff: [key] }));
    } else {
      dispatch(ac.clientEdition.marginProfileModified({ clientId, diff: { [key]: value } }));
    }
  });
};

export const saveChanges = (): AppThunk => async (
  dispatch,
  getState,
  { actionCreators: ac, selectors: sl, api },
) => {
  const state = getState();
  const clients = sl.getAllClientsRecord(state);
  const diffs = sl.getEditedClientsDiffs(state);

  if (sl.hasClientEditionMissingChangeReasons(state)) {
    dispatch(ac.clientEdition.reasonModalToggled());
    return;
  }

  const modifiedClients = strictEntries(diffs).map(
    ([id, { changeReason: reasonForChange, ...diff }]) => ({
      id,
      name: narrowDefined(clients[id]).name,
      ...diff,
      reasonForChange,
    }),
  );
  dispatch(ac.clientEdition.editionSaveSent());
  const updatedClients = await api.clients.updateClients(modifiedClients);
  const floatingMargins = await api.floatingMargins.getClientFloatingMargins();
  if (modifiedClients.some((x) => x.salesMarginProfileId === CustomMarginProfileId)) {
    await dispatch(ac.marginProfiles.fetchMarginProfiles());
  }
  dispatch(
    ac.clientEdition.editionSaved({ clients: updatedClients, floatingMargins: floatingMargins }),
  );
};
