import { connect } from 'react-redux';
import { MapStateToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, selectors } from 'store';

export interface CrashModalConnectOwnProps {}

export interface CrashModalConnectStateProps {
  error: string | null;
}

// you can whitelist selectors after implementation
export type CrashModalConnectSelectors = Selectors;
/*
export type CrashModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type CrashModalConnectSelectors = Pick<Selectors, CrashModalConnectSelectorsKeys>;
*/

export const mapStateToPropsCrashModal: MapStateToPropsHOF<
  CrashModalConnectStateProps,
  CrashModalConnectOwnProps,
  AppState,
  CrashModalConnectSelectors
> = sl => state => ({
  error: sl.getError(state) ?? null,
});

export const connectCrashModal = connect(mapStateToPropsCrashModal(selectors));
