import { Middleware } from '@reduxjs/toolkit';
import { AppState } from './store';
import { selectors as sl } from './selectors';
import { actionCreators as ac } from './actions';
import { Matomo } from 'utils/matomo';
import { debounce } from 'utils/debounce';
import { narrowDefined } from 'utils/asserts';

export const getMatomoMiddleware = (matomo: Matomo): Middleware<{}, AppState> => {
  const trackClientNameChanged = debounce(500, matomo.track);
  return (store) => (next) => (action) => {
    const state = store.getState();

    // Let thunks go through
    if (typeof action !== 'function') {
      // UI
      if (ac.ui.appBootstrapped.match(action)) {
        matomo.init(action.payload);
      }
      if (ac.ui.pageChanged.match(action)) {
        switch (action.payload) {
          case 'ClientsMargins':
            matomo.track('Clients margins', 'Page loaded');
            break;
          case 'MarginsPresets':
            matomo.track('Margins presets', 'Page loaded');
            break;
        }
      }
      if (ac.ui.authExpired.match(action)) {
        matomo.track('Workspace', 'Session timed out');
      }

      // Filters
      if (ac.filters.clientNameFilterChanged.match(action)) {
        trackClientNameChanged('Clients margins', 'Client search changed', action.payload);
      }
      if (ac.filters.activeClientsOnlyFilterToggled.match(action)) {
        matomo.track('Clients margins', 'Filter changed', 'Active clients only');
      }
      if (ac.filters.customizedMarginsOnlyFilterToggled.match(action)) {
        matomo.track('Clients margins', 'Filter changed', 'Customized margins only');
      }
      if (ac.filters.salesProfileFilterToggled.match(action)) {
        matomo.track(
          'Clients margins',
          'Filter changed',
          `Credit/Swap margin profile - ${action.payload}`,
        );
      }
      if (ac.filters.devLProfileFilterToggled.match(action)) {
        matomo.track('Clients margins', 'Filter changed', `DEVL margin - ${action.payload}`);
      }
      if (ac.filters.pcruMarginFilterToggled.match(action)) {
        matomo.track('Clients margins', 'Filter changed', `PCRU margin - ${action.payload}`);
      }
      if (ac.filters.filtersCleared.match(action)) {
        matomo.track('Clients margins', 'Filters cleared');
      }

      // Client edition
      if (ac.clientEdition.toggleEdition.match(action)) {
        matomo.track('Clients margins', 'Edition toggled');
      }
      if (ac.clientEdition.marginProfileModified.match(action)) {
        matomo.track(
          'Clients margins',
          'Margin changed',
          `${narrowDefined(sl.getClientById(state, action.payload.clientId)).name} - ${
            action.payload.diff
          }`,
        );
      }
      if (ac.clientEdition.editionSaved.match(action)) {
        matomo.track(
          'Clients margins',
          'Edition saved',
          `${sl.getEditedCells(state)} cells edited`,
        );
      }

      // Client form
      if (ac.clientForm.clientFormModalOpened.match(action)) {
        matomo.track(
          'Client modal',
          'Opened',
          action.payload === undefined
            ? 'Add'
            : `Edit - ${narrowDefined(sl.getClientById(state, action.payload.id)).name}`,
        );
      }
      if (ac.clientForm.clientFormFieldChanged.match(action)) {
        matomo.track('Client modal', 'Field changed', `${Object.keys(action.payload).join('|')}`);
      }
      if (ac.clientForm.clientFormDisplayModeChanged.match(action)) {
        matomo.track('Client modal', 'Mode changed', action.payload.displayMode);
      }
      if (ac.clientForm.clientFormSubmitReceived.match(action)) {
        matomo.track('Client modal', 'Saved');
      }
      if (ac.clientForm.clientFormModalClosed.match(action)) {
        matomo.track('Client modal', 'Closed');
      }

      // Margins presets
      if (ac.marginProfileEdition.editionToggled.match(action)) {
        matomo.track('Margins presets', 'Edition toggled');
      }
      if (ac.marginProfileEdition.editionSaved.match(action)) {
        matomo.track(
          'Margins presets',
          'Edition saved',
          `${sl.getMarginProfileEditionCellCount(state)} cells edited`,
        );
      }
    }

    return next(action);
  };
};
