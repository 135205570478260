import { userDataSlice } from './userData';
import * as userDataThunks from './userData/userDataThunks';
import { filtersSlice } from './filters';
import { displayedProductsSlice } from './displayedProducts';
import { uiSlice } from './ui';
import { clientsSlice } from './clients';
import * as clientsThunks from './clients/clientsThunks';
import { marginProfilesSlice } from './marginProfiles';
import * as marginProfilesThunk from './marginProfiles/marginProfilesThunks';
import { clientFormSlice } from './clientForm';
import * as clientFormThunks from './clientForm/clientFormThunks';
import { clientEditionSlice } from './clientEdition';
import * as clientEditionThunks from './clientEdition/clientEditionThunks';
import { marginProfilesEditionSlice } from './marginProfilesEdition/marginProfilesEditionSlice';
import * as marginProfileEditionThunks from './marginProfilesEdition/marginProfilesEditionThunks';
import { clientDuplicationSlice } from './clientDuplication/clientDuplicationSlice';
import * as clientDuplicationThunks from './clientDuplication/clientDuplicationThunks';
import { floatingMarginsSlice } from './floatingMargins';
import * as floatingMarginsThunks from './floatingMargins/floatingMarginsThunks';

export const actionCreators = {
  [clientsSlice.name]: { ...clientsSlice.actions, ...clientsThunks },
  [floatingMarginsSlice.name]: { ...floatingMarginsSlice.actions, ...floatingMarginsThunks },
  [marginProfilesSlice.name]: { ...marginProfilesSlice.actions, ...marginProfilesThunk },
  [uiSlice.name]: { ...uiSlice.actions },
  [filtersSlice.name]: { ...filtersSlice.actions },
  [displayedProductsSlice.name]: { ...displayedProductsSlice.actions },
  [userDataSlice.name]: { ...userDataSlice.actions, ...userDataThunks },
  [clientFormSlice.name]: { ...clientFormSlice.actions, ...clientFormThunks },
  [clientEditionSlice.name]: { ...clientEditionSlice.actions, ...clientEditionThunks },
  [clientDuplicationSlice.name]: { ...clientDuplicationSlice.actions, ...clientDuplicationThunks },
  [marginProfilesEditionSlice.name]: {
    ...marginProfilesEditionSlice.actions,
    ...marginProfileEditionThunks,
  },
} as const;

export type ActionCreators = typeof actionCreators;
