import { MapStateToPropsHOF } from 'typings/redux-connect';
import { connect } from 'react-redux';
import { Page } from 'store/ui';
import { AppState, Selectors, selectors } from 'store';

export interface PageSwitchStateProps {
  currentPage: Page;
}

const mapStateToProps: MapStateToPropsHOF<
  PageSwitchStateProps,
  {},
  AppState,
  Selectors
> = sl => state => ({
  currentPage: sl.getActivePage(state),
});

export const connectPageSwitch = connect(mapStateToProps(selectors));
