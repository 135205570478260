import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BackendUserData } from 'api/userData';

const featureToggles = [
  'canEditClientMargins',
  'canEditMaturities',
  'iamAuthorizationActivated',
  'capFlooredActivated',
] as const;

type FeatureToggleKeys = typeof featureToggles[number];
export type FeatureToggles = Record<FeatureToggleKeys, boolean>;

const defaultFeatureToggles: FeatureToggles = {
  canEditClientMargins: false,
  canEditMaturities: false,
  iamAuthorizationActivated: false,
  capFlooredActivated: true,
};

export interface UserData {
  featureToggles: FeatureToggles;
  isFetching: boolean;
}

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    featureToggles: defaultFeatureToggles,
    isFetching: false,
  } as UserData,
  reducers: {
    userDataRequested: (state) => {
      state.isFetching = true;
    },
    userDataReceived: (state, { payload }: PayloadAction<BackendUserData>) => {
      state.featureToggles = {
        ...state.featureToggles,
        ...payload.featureToggles,
      };
      state.isFetching = false;
    },
  },
});
