import React, { useCallback, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from 'components/shared/LoadingButton';
import { connectTimeoutModal } from './connect/connectTimeoutModal';

export interface TimeoutModalProps {
  isOpen: boolean;
}

const TimeoutModalRaw: React.FunctionComponent<TimeoutModalProps> = ({ isOpen }) => {
  const [isLoading, setLoading] = useState(false);
  const reload = useCallback(() => {
    setLoading(true);
    window.location.reload();
  }, [setLoading]);
  return (
    <Modal isOpen={isOpen} className="border border-warning" backdrop="static">
      <ModalHeader tag="h3" className="text-warning">
        <FormattedMessage id="timeoutModal.title" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="timeoutModal.description" />
      </ModalBody>
      <ModalFooter>
        <LoadingButton className="btn btn-lg btn-warning" loading={isLoading} onClick={reload}>
          <FormattedMessage id="common.reload" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export const TimeoutModal = connectTimeoutModal(TimeoutModalRaw);
