import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { AccountPicker } from './AccountPicker';
import {
  SelectSalesMarginProfile,
  SelectDevLMarginProfile,
  SelectPcruMargin,
} from './SelectMarginProfile';
import { connectClientFormModal } from './connect/connectClientFormModal';
import { ClientName } from './ClientName';
import { IsActive } from './IsActive';
import { Maturities } from './Maturities';
import { ChangeReasonModal } from 'components/ChangeReasonModal';
import { OverflowableModalBody } from 'components/shared/Modal/OverflowableModalBody';
import { LoadingButton } from 'components/shared/LoadingButton';

export interface ClientFormModalProps {
  displayMode: 'Add' | 'Edit' | 'SoftEdit';
  isModalOpen: boolean;
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
  toggleModal(): void;
  submit(): void;
  clear(): void;
}

const saveButtonLabel: Record<ClientFormModalProps['displayMode'], string> = {
  Add: 'common.add',
  Edit: 'common.edit',
  SoftEdit: 'clientForm.saveAndGoToClientsMargins',
};

const ClientFormModalRaw: React.FunctionComponent<ClientFormModalProps> = ({
  displayMode,
  isModalOpen,
  isReadyToSubmit,
  isSubmitting,
  toggleModal,
  submit,
  clear,
}) => (
  <Modal isOpen={isModalOpen} onClosed={clear}>
    <ModalHeader tag="h3" toggle={toggleModal}>
      <FormattedMessage id={`clientForm.title.${displayMode === 'Edit' ? 'Edit' : 'Add'}`} />
    </ModalHeader>
    <OverflowableModalBody>
      <div className="form-group">
        <label>
          <FormattedMessage id="clientForm.clientName" />
        </label>
        {displayMode === 'Edit' ? <ClientName /> : <AccountPicker />}
        {displayMode === 'SoftEdit' && (
          <p className="py-3 text-info">
            <i className="icon icon-sm mr-2">info_outline</i>
            <FormattedMessage id="clientForm.clientAlreadyAdded" />
          </p>
        )}
      </div>
      <div className="form-row">
        <div className="form-group col-12">
          <label>
            <FormattedMessage id="clientForm.maturities" />
          </label>
          <Maturities />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label>
            <FormattedMessage id="clientForm.salesMarginProfile" />
          </label>
          <SelectSalesMarginProfile />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label>
            <FormattedMessage id="clientForm.devLMarginProfile" />
          </label>
          <SelectDevLMarginProfile />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label>
            <FormattedMessage id="clientForm.pcruMarginProfile" />
          </label>
          <SelectPcruMargin />
        </div>
      </div>
      {displayMode !== 'Add' && (
        <div className="form-row">
          <div className="form-group col-12">
            <IsActive />
          </div>
        </div>
      )}
      <ChangeReasonModal />
    </OverflowableModalBody>
    <ModalFooter>
      <button className="btn btn-lg btn-flat-secondary" onClick={toggleModal}>
        <FormattedMessage id="common.cancel" />
      </button>
      <LoadingButton
        className="btn btn-lg btn-primary"
        loading={isSubmitting}
        disabled={!isReadyToSubmit || isSubmitting}
        onClick={submit}
      >
        <FormattedMessage id={saveButtonLabel[displayMode]} />
      </LoadingButton>
    </ModalFooter>
  </Modal>
);

export const ClientFormModal = connectClientFormModal(ClientFormModalRaw);
