import * as marginProfilesSelectors from './marginProfiles/marginProfilesSelectors';
import * as filtersSelectors from './filters/filtersSelectors';
import * as displayedProductsSelectors from './displayedProducts/displayedProductsSelectors';
import * as clientsSelectors from './clients/clientsSelectors';
import * as clientFormSelectors from './clientForm/clientFormSelectors';
import * as uiSelectors from './ui/uiSelectors';
import * as userDataSelectors from './userData/userDataSelectors';
import * as clientEditionSelectors from './clientEdition/clientEditionSelectors';
import * as marginProfileEditionSelectors from './marginProfilesEdition/marginProfilesEditionSelectors';
import * as errorSelectors from './error/errorSelectors';
import * as clientDuplicationSelectors from './clientDuplication/clientDuplicationSelectors';
import * as floatingMarginsSelectors from './floatingMargins/floatingMarginsSelectors';

export const selectors = {
  ...marginProfilesSelectors,
  ...filtersSelectors,
  ...displayedProductsSelectors,
  ...clientsSelectors,
  ...clientFormSelectors,
  ...uiSelectors,
  ...userDataSelectors,
  ...clientEditionSelectors,
  ...marginProfileEditionSelectors,
  ...errorSelectors,
  ...clientDuplicationSelectors,
  ...floatingMarginsSelectors,
};
export type Selectors = typeof selectors;
