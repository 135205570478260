import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultFormState, FormState } from 'store/utils/form/state';
import { Client, minimumLowerMaturityRange, maximumUpperMaturityRange } from 'store/clients';
import { FloatingMargin } from 'store/floatingMargins';
import { clamp } from 'utils/number';

export type ClientFormState = {
  displayMode: 'Add' | 'Edit' | 'SoftEdit';
  clientId?: number;
  isModalOpen: boolean;
  isReasonModalOpen: boolean;
} & FormState<ClientForm>;

export interface ClientForm {
  name: string | undefined;
  lowerMaturityRange: number;
  upperMaturityRange: number;
  salesMarginProfileId: number | undefined;
  changeReason?: string;
  devLMarginProfileId: number | undefined;
  pcruMargin: number | undefined;
  isActive: boolean;
}

export const CustomMarginProfileId = -1;

const initialState: ClientFormState = {
  displayMode: 'Add',
  isModalOpen: false,
  isReasonModalOpen: false,
  ...defaultFormState<ClientForm>({
    name: undefined,
    lowerMaturityRange: 1,
    upperMaturityRange: 30,
    salesMarginProfileId: undefined,
    devLMarginProfileId: undefined,
    pcruMargin: undefined,
    isActive: true,
  }),
};

export const clientFormSlice = createSlice({
  name: 'clientForm',
  initialState,
  reducers: {
    clientFormModalOpened: (state, { payload: client }: PayloadAction<Client | undefined>) => {
      state.isModalOpen = true;
      if (client !== undefined) {
        state.displayMode = 'Edit';
        state.clientId = client.id;
        state.values.name = client.name;
        state.values.salesMarginProfileId = client.salesMarginProfileId;
        state.values.devLMarginProfileId = client.devLMarginProfileId;
        state.values.pcruMargin = client.pcruMargin;
        state.values.lowerMaturityRange = client.lowerMaturityRange;
        state.values.upperMaturityRange = client.upperMaturityRange;
        state.values.isActive = client.isActive;
      }
    },
    clientFormModalClosed: (state) => {
      state.isModalOpen = false;
    },
    clientFormDisplayModeChanged: (
      state,
      { payload }: PayloadAction<DisplayModeChangedPayload>,
    ) => {
      state.displayMode = payload.displayMode;
      switch (payload.displayMode) {
        case 'Add':
          state.clientId = undefined;
          break;
        case 'Edit':
        case 'SoftEdit':
          state.clientId = payload.client.id;
          state.values.name = payload.client.name;
          state.values.salesMarginProfileId = payload.client.salesMarginProfileId;
          state.values.devLMarginProfileId = payload.client.devLMarginProfileId;
          state.values.pcruMargin = payload.client.pcruMargin;
          state.values.lowerMaturityRange = payload.client.lowerMaturityRange;
          state.values.upperMaturityRange = payload.client.upperMaturityRange;
          state.values.isActive = payload.client.isActive;
          break;
      }
    },
    clientFormCleared: () => initialState,
    clientFormFieldChanged: (state, { payload }: PayloadAction<Partial<ClientForm>>) => {
      if (payload.lowerMaturityRange !== undefined) {
        payload.lowerMaturityRange = clamp(
          payload.lowerMaturityRange,
          minimumLowerMaturityRange,
          maximumUpperMaturityRange,
        );
        payload.upperMaturityRange = clamp(
          payload.upperMaturityRange ?? state.values.upperMaturityRange,
          payload.lowerMaturityRange,
          maximumUpperMaturityRange,
        );
      }
      if (payload.upperMaturityRange !== undefined) {
        payload.upperMaturityRange = clamp(
          payload.upperMaturityRange,
          minimumLowerMaturityRange,
          maximumUpperMaturityRange,
        );
        payload.lowerMaturityRange = clamp(
          payload.lowerMaturityRange ?? state.values.lowerMaturityRange,
          minimumLowerMaturityRange,
          payload.upperMaturityRange,
        );
      }
      state.values = {
        ...state.values,
        ...payload,
      };
      state.isReadyToSubmit =
        state.values.name !== undefined &&
        state.values.salesMarginProfileId !== undefined &&
        state.values.devLMarginProfileId !== undefined &&
        state.values.pcruMargin !== undefined;
    },
    clientFormSubmitSent: (state) => {
      state.isSubmitting = true;
    },
    clientFormSubmitReceived: (
      _state,
      _action: PayloadAction<{ client: Client; floatingMargins: FloatingMargin[] }>,
    ) => {
      return initialState;
    },
    clientFormReasonModalToggled: (state) => {
      state.isReasonModalOpen = !state.isReasonModalOpen;
    },
  },
});

export type DisplayModeChangedPayload =
  | {
      displayMode: 'Add';
    }
  | {
      displayMode: 'Edit' | 'SoftEdit';
      client: Client;
    };
