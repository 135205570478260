import React from 'react';
import { FormattedMessage } from 'react-intl';
import { changeReasons } from 'store/clients';
import { Select } from 'components/shared/Form/Select';
import { connectSelectReason } from './connectSelectReason';

export interface SelectReasonProps {
  clientId: number;
  clientName: string;
  value: string | undefined;
  onChange(value: string): void;
}

const options: [string, string][] = changeReasons.map(x => [x, x]);

const SelectReasonRaw: React.FunctionComponent<SelectReasonProps> = ({
  clientName,
  value,
  onChange,
}) => (
  <div className="form-group">
    <label className="font-weight-normal text-primary">
      <FormattedMessage
        id="changeReasonModal.selectReason"
        values={{
          client: clientName,
          strong: (msg: string) => <strong className="text-secondary">{msg}</strong>,
        }}
      />
    </label>
    <Select value={value} options={options} onChange={onChange} />
  </div>
);

export const SelectReason = connectSelectReason(SelectReasonRaw);
