import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connectActiveClient } from './connectActiveClient';

export interface ActiveClientProps {
  activeClientsOnly: boolean;
  onActiveClientsOnlyToggle(): void;
}

const ActiveClientRaw: React.FunctionComponent<ActiveClientProps> = ({
  activeClientsOnly,
  onActiveClientsOnlyToggle,
}) => {
  return (
    <div className="form-group mb-0">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          id="activeClientsOnly"
          className="custom-control-input"
          checked={activeClientsOnly}
          onChange={onActiveClientsOnlyToggle}
        />
        <label htmlFor="activeClientsOnly" className="custom-control-label">
          <FormattedMessage id="filters.activeClientsOnly" />
        </label>
      </div>
    </div>
  );
};

export const ActiveClient = connectActiveClient(ActiveClientRaw);
