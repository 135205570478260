import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, selectors, ActionCreators, actionCreators } from 'store';

export interface IsActiveConnectOwnProps {}

export interface IsActiveConnectStateProps {
  value: boolean;
}

export interface IsActiveConnectDispatchProps {
  onChange(value: boolean): void;
}

// you can whitelist selectors after implementation
export type IsActiveConnectSelectors = Selectors;
/*
export type IsActiveConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type IsActiveConnectSelectors = Pick<Selectors, IsActiveConnectSelectorsKeys>;
*/

export const mapStateToPropsIsActive: MapStateToPropsHOF<
  IsActiveConnectStateProps,
  IsActiveConnectOwnProps,
  AppState,
  IsActiveConnectSelectors
> = sl => state => ({
  value: sl.getClientFormValue(state, 'isActive'),
});

// you can whitelist action creators after implementation
export type IsActiveConnectActionCreators = ActionCreators;
/*
export type IsActiveConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type IsActiveConnectActionCreators = Pick<ActionCreators, IsActiveConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsIsActive: MapDispatchToPropsHOF<
  IsActiveConnectDispatchProps,
  IsActiveConnectOwnProps,
  IsActiveConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  onChange: isActive =>
    dispatch(
      ac.clientForm.clientFormFieldChanged({
        isActive,
      }),
    ),
});

export const connectIsActive = connect(
  mapStateToPropsIsActive(selectors),
  mapDispatchToPropsIsActive(actionCreators),
);
