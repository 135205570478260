import React from 'react';
import ReactDOM from 'react-dom';
import { setupSgwtConnectWidget, sgwtConnect } from 'bootstrap/sgwtConnect';
import { initStore } from 'bootstrap/store';
import { setupAgGrid } from 'bootstrap/aggrid';
import App from 'components/App';

import '@sg-bootstrap/icons/dist/index.css';
import '@sg-bootstrap/core/build/sg-bootstrap-standard.min.css';
import '@sg-bootstrap/ag-grid/dist/theme-standard.min.css';

setupAgGrid();
setupSgwtConnectWidget();

if (sgwtConnect.isAuthorized()) {
  initStore();
  ReactDOM.render(<App />, document.getElementById('root'));
} else if (sgwtConnect.getAuthorizationError()) {
  document.body.innerHTML = `
    <div class="alert alert-danger" role="alert">
      Authorization error: ${sgwtConnect.getAuthorizationError()}.
    </div>`;
} else {
  sgwtConnect.requestAuthorization();
}
