import { appConfig } from 'config';
import React from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-help-center': any;
    }
  }
}

export const HelpCenter: React.FunctionComponent = () => {
  return (
    <aside>
      <sgwt-help-center
        sg-connect-support="sg-connect-v2"
        application-id="sgme-wmc"
        default-send-to={btoa(appConfig.helpCenter_sendMailto)}
        mail-subject="[SGWT Help Center] - [WMC] - User message"
      />
    </aside>
  );
};
