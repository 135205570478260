import { PayloadAction } from '@reduxjs/toolkit';

export interface FetchableState {
  isFetching: boolean;
}

export const defaultFetchableState: FetchableState = {
  isFetching: false,
};

export const createFetchableReducers = <S extends FetchableState, T>(options: {
  fetch?: (state: S) => S;
  fetched?: (state: S, action: PayloadAction<T[]>) => S;
}) => ({
  fetch: (state: S) => {
    state.isFetching = true;
    if (options.fetch !== undefined) {
      options.fetch(state);
    }
  },
  fetched: (state: S, action: PayloadAction<T[]>) => {
    state.isFetching = false;
    if (options.fetched !== undefined) {
      options.fetched(state, action);
    }
  },
});
