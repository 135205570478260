import { AppThunk } from 'store';
import { assertDefined } from 'utils/asserts';

export const fetchClients = (): AppThunk<Promise<void>> => async (
  dispatch,
  _getState,
  { api, actionCreators },
) => {
  dispatch(actionCreators.clients.fetch());
  const clients = await api.clients.getClients();
  // some clients have weird spaces in their name, and thus break comparison
  clients.forEach(client => client.name = client.name.trim());
  dispatch(actionCreators.clients.fetched(clients));
};

export const deactivateClient = (clientId: number): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
  { api, selectors: sl, actionCreators: ac },
) => {
  const state = getState();
  const client = sl.getClientById(state, clientId);
  assertDefined(client, `No found client for id ${clientId}`);
  const { name, salesMarginProfileId, devLMarginProfileId, pcruMargin } = client;
  const updatedClient = await api.clients.updateClient({
    id: clientId,
    name,
    salesMarginProfileId,
    devLMarginProfileId,
    pcruMargin,
    isActive: false,
  });
  dispatch(ac.clients.clientDeactivated(updatedClient));
};
