import React, { useCallback, useEffect } from 'react';
import { appConfig } from 'config';

export interface AccountPickerProps {
  onChange(value: string): void;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'ic-sgb4-simple-account-picker': IcAccountPickerAttributes;
    }

    interface IcAccountPickerAttributes {
      placeholder?: string;
      'max-result-count'?: number;
      'selected-id'?: string;
      'readonly-id'?: string;
      'soa-url'?: string;
      'sso-v2'?: boolean;
      'user-email'?: string;
      'in-error'?: string;
      'error-message'?: string;
    }
  }
}

type IcAccountPickerChangedEvent = CustomEvent<{
  accounts: Array<{
    bdrCommercialId: string;
    bdrCommercialLevel: string;
    bdrLegalId: string;
    bdrLegalLevel: string;
    icId: string;
    name: string;
    countryName: string;
  }>;
}>;

export const IcAccountPicker: React.FunctionComponent<AccountPickerProps> = ({ onChange }) => {
  const changeHandler = useCallback(
    // some clients have weird spaces in their name, and thus break comparison
    (ev: IcAccountPickerChangedEvent) => onChange(ev.detail?.accounts[0]?.name.trim()),
    [onChange],
  );
  useEffect(() => {
    window.addEventListener(
      'ic-sgb4-simple-account-picker--changed',
      changeHandler as EventListener,
    );
    return () => {
      window.removeEventListener(
        'ic-sgb4-simple-account-picker--changed',
        changeHandler as EventListener,
      );
    };
  }, [changeHandler]);
  return <ic-sgb4-simple-account-picker soa-url={appConfig.icSoaUrl} sso-v2={true} />;
};
