import { AssertionError } from 'assert';

export function assertDefined<T>(actual: T, message?: string): asserts actual is NonNullable<T> {
  if (actual === undefined || actual === null) {
    throw new AssertionError({
      message: message ?? 'Unexpected undefined value',
      actual,
    });
  }
}

/**
 * Asserts that `value`is defined and returns it with its type narrowed
 */
export function narrowDefined<T>(value: T) {
  assertDefined(value);
  return value;
}

export function assertUnreachable(actual: never, message: string): asserts actual is never {
  throw new AssertionError({ message, actual });
}

export function assertIs<T>(actual: any, condition: any): asserts actual is T {
  if (!condition)
    throw new AssertionError({
      message: `the type of actual is different from the expected type`,
      actual,
    });
}
