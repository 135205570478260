import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { getFilters } from 'store/filters/filtersSelectors';
import { clientsAdapter } from './clientsSlice';
import { getMarginProfiles } from 'store/marginProfiles/marginProfilesSelectors';
import { narrowDefined } from 'utils/asserts';

const getClientsState = (state: AppState) => state.clients;

const clientsSelectors = clientsAdapter.getSelectors(getClientsState);

export const isClientsFetching = (state: AppState) => getClientsState(state).isFetching;

export const getAllClientsRecord = clientsSelectors.selectEntities;

export const getAllClientsArray = clientsSelectors.selectAll;

export const getFilteredClients = createSelector(
  [getAllClientsArray, getMarginProfiles, getFilters],
  (clients, marginProfiles, filters) =>
    clients.filter(
      (client) =>
        (filters.clientName.length === 0 ||
          client.name.toLowerCase().includes(filters.clientName.toLowerCase())) &&
        (!filters.activeClientsOnly || client.isActive) &&
        (filters.customizedMarginsOnly
          ? !narrowDefined(marginProfiles[client.salesMarginProfileId]).isPreset
          : filters.salesProfiles.length === 0 ||
            filters.salesProfiles.includes(client.salesMarginProfileId)) &&
        (filters.devLProfiles.length === 0 ||
          filters.devLProfiles.includes(client.devLMarginProfileId)) &&
        (filters.pcruMargins.length === 0 || filters.pcruMargins.includes(client.pcruMargin)) &&
        client.lowerMaturityRange <= filters.upperMaturityRange &&
        client.upperMaturityRange >= filters.lowerMaturityRange,
    ),
);

export const getClientById = clientsSelectors.selectById;

export const getClientByName = (state: AppState, name: string) =>
  getAllClientsArray(state).find((client) => client.name === name);
