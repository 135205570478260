export const debounce = <F extends (...args: any[]) => void>(delay: number, func: F) => {
  let timeout: ReturnType<typeof setTimeout> | undefined = undefined;

  return (...args: Parameters<F>) => {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), delay);
  };
};
