import { AppThunk } from 'store';
import { UpdateMarginsPayload } from 'api/marginProfiles';
import { isDevlMargin, isSalesMargin } from 'store/marginProfiles/marginProfilesSlice';
import { narrowDefined } from 'utils/asserts';

export const modifyMargin = (marginId: string, value: number): AppThunk => (
  dispatch,
  getState,
  { selectors: sl, actionCreators: ac },
) => {
  const state = getState();

  const oldValue = sl
    .getAllMarginProfiles(state)
    .map(narrowDefined)
    .flatMap((x) =>
      isSalesMargin(x)
        ? [...x.fixedMargins, ...x.floating3M, ...x.floating6M, ...x.floating12M]
        : isDevlMargin(x)
        ? x.margins
        : [],
    )
    .find((x) => x.id === marginId)?.value;
  if (oldValue === value) {
    dispatch(ac.marginProfileEdition.marginResetted({ marginId }));
  } else {
    dispatch(ac.marginProfileEdition.marginModified({ marginId, value }));
  }
};

export const saveEdition = (): AppThunk => async (
  dispatch,
  getState,
  { api, selectors: sl, actionCreators: ac },
) => {
  const state = getState();

  const marginsDiff = sl.getMarginProfileDiff(state);
  const payload: UpdateMarginsPayload = Object.entries(marginsDiff).flatMap(([id, value]) => ({
    id,
    value,
  }));
  dispatch(ac.marginProfileEdition.editionSaveSent());
  await api.marginProfiles.updateMargins(payload);

  const marginProfiles = await api.marginProfiles.getMarginProfiles();
  const floatingMargins = await api.floatingMargins.getClientFloatingMargins();

  dispatch(ac.marginProfileEdition.editionSaved({ marginProfiles, floatingMargins }));
};
