import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Client } from 'store/clients';
import { MarginProfile } from 'store/marginProfiles';
import { ClientFormModal } from 'components/ClientFormModal';
import { DuplicateClientModal } from 'components/DuplicateClientModal';
import { DeleteClientModal } from 'components/DeleteClientModal';
import { useFeatureToggles } from 'components/hooks/useFeatureToggles';
import { useToggle } from 'components/hooks/useToggle';
import { ClientsMarginsTable } from './ClientsMarginsTable/ClientsMarginsTable';
import { ClientsMarginsFilters } from './Filters/ClientsMarginsFilters';
import { connectClientsMargins } from './connect';
import { EditClientsMargins } from './EditClientsMargins/EditClientsMargins';
import { ReadonlyMarginPresets } from './ReadonlyMarginPresets/ReadonlyMarginPresets';
import { FloatingMargin } from 'store/floatingMargins';

export interface ClientsMarginsProps {
  isFetching: boolean;
  clients: readonly Client[];
  marginProfiles: Record<number, MarginProfile>;
  floatingMargins: Record<string, FloatingMargin>;
  toggleClientModal(): void;
  goToPresets(): void;
}

const NoClientsFound = () => (
  <div className="d-flex flex-column align-items-center mb-n4 mx-n4 pt-5 flex-grow-1 text-secondary bg-lvl2">
    <i className="icon icon-xl">business</i>
    <h4>
      <FormattedMessage id="clientsMargins.noClientsFound.title" />
    </h4>
    <FormattedMessage id="clientsMargins.noClientsFound.description" />
  </div>
);

const ClientsMarginsRaw: React.FunctionComponent<ClientsMarginsProps> = ({
  isFetching,
  clients,
  marginProfiles,
  floatingMargins,
  toggleClientModal,
  goToPresets,
}) => {
  const [isMarginProfilesOpen, toggleMarginProfiles, closeToggleMarginProfiles] = useToggle();
  const [isDuplicateClientModalOpen, toggleDuplicateClientModal] = useToggle();
  const [isDeleteClientModalOpen, toggleDeleteClientModal] = useToggle();
  const [selectedClientId, setSelectedClientId] = useState<number>();
  const openActionModal = useCallback(
    (toggleModal: () => void) => (clientId: number) => {
      setSelectedClientId(clientId);
      toggleModal();
    },
    [setSelectedClientId],
  );
  const featureToggles = useFeatureToggles();

  return (
    <>
      <ClientFormModal />
      {selectedClientId !== undefined && (
        <>
          <DuplicateClientModal
            isOpen={isDuplicateClientModalOpen}
            toggle={toggleDuplicateClientModal}
            clientId={selectedClientId}
          />
          <DeleteClientModal
            isOpen={isDeleteClientModalOpen}
            toggle={toggleDeleteClientModal}
            clientId={selectedClientId}
          />
        </>
      )}
      <div className="border-bottom p-4 d-flex align-items-center">
        <h1 className="display-3 flex-grow-1 m-0">
          <FormattedMessage id="clientsMargins.title" />
        </h1>
        <button className="btn btn-lg sgbs-btn-default mr-2" onClick={toggleClientModal}>
          <FormattedMessage id="clientsMargins.addClient" />
        </button>
        <button className="btn btn-lg btn-text-icon sgbs-btn-default" onClick={goToPresets}>
          <FormattedMessage id="clientsMargins.openMarginPresets" />
          <i className="icon">arrow_forward</i>
        </button>
      </div>
      <div className="p-4 flex-grow-1 d-flex flex-column">
        {isFetching ? (
          <div className="spinner-grow">
            <FormattedMessage id="common.loading" />
          </div>
        ) : (
          <>
            <ClientsMarginsFilters />
            {featureToggles.canEditClientMargins && (
              <EditClientsMargins
                previewPreset={toggleMarginProfiles}
                closePreset={closeToggleMarginProfiles}
              />
            )}
            <div className={clients.length === 0 ? 'd-none' : 'flex-grow-1'}>
              <ClientsMarginsTable
                clients={clients}
                marginProfiles={marginProfiles}
                floatingMargins={floatingMargins}
                duplicateClient={openActionModal(toggleDuplicateClientModal)}
                deleteClient={openActionModal(toggleDeleteClientModal)}
              />
            </div>
            {clients.length === 0 && <NoClientsFound />}
            {isMarginProfilesOpen && (
              <ReadonlyMarginPresets close={toggleMarginProfiles} edit={goToPresets} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export const ClientsMargins = connectClientsMargins(ClientsMarginsRaw);
