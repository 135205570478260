import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { Selectors, selectors, AppState, ActionCreators, actionCreators } from 'store';
import { ClientsDiffs, ClientDiff } from 'store/clientEdition';
import { Products } from 'store/displayedProducts';

export interface ClientsMarginsTableConnectOwnProps {}

export interface ClientsMarginsTableConnectStateProps {
  clientsDiffs: ClientsDiffs;
  isEditing: boolean;
  displayedProducts: Products[];
  lowerMaturityRange: number;
  upperMaturityRange: number;
}

export interface ClientsMarginsTableConnectDispatchProps {
  modifyMarginProfile(clientId: number, diff: Partial<ClientDiff>): void;
  editClient(clientId: number): void;
}

// you can whitelist selectors after implementation
export type ClientsMarginsTableConnectSelectors = Selectors;
/*
export type ClientsMarginsTableConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ClientsMarginsTableConnectSelectors = Pick<Selectors, ClientsMarginsTableConnectSelectorsKeys>;
*/

export const mapStateToPropsClientsMarginsTable: MapStateToPropsHOF<
  ClientsMarginsTableConnectStateProps,
  ClientsMarginsTableConnectOwnProps,
  AppState,
  ClientsMarginsTableConnectSelectors
> = (sl) => (state, _ownProps) => ({
  clientsDiffs: sl.getEditedClientsDiffs(state),
  isEditing: sl.isEditingClients(state),
  displayedProducts: sl.getDisplayedProducts(state),
  lowerMaturityRange: sl.getFilter(state, 'lowerMaturityRange'),
  upperMaturityRange: sl.getFilter(state, 'upperMaturityRange'),
});

// you can whitelist action creators after implementation
type ClientsMarginsTableConnectActionCreators = ActionCreators;
/*
export type EditClientsMarginConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type EditClientsMarginConnectActionCreators = Pick<ActionCreators, EditClientsMarginConnectActionCreatorsKeys>;
*/

const mapDispatchToPropsClientsMarginsTable: MapDispatchToPropsHOF<
  ClientsMarginsTableConnectDispatchProps,
  ClientsMarginsTableConnectOwnProps,
  ClientsMarginsTableConnectActionCreators
> = (ac) => (dispatch, _ownProps) => ({
  modifyMarginProfile: (clientId, diff: Partial<ClientDiff>) =>
    dispatch(ac.clientEdition.modifyMarginProfile(clientId, diff)),
  editClient: (clientId) => dispatch(ac.clientForm.openEditClientForm(clientId)),
});

export const connectClientsMarginsTable = connect(
  mapStateToPropsClientsMarginsTable(selectors),
  mapDispatchToPropsClientsMarginsTable(actionCreators),
);
