import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';
import { narrowDefined } from 'utils/asserts';

export interface DeleteClientModalConnectOwnProps {
  clientId: number;
}

export interface DeleteClientModalConnectStateProps {
  clientName: string;
}

export interface DeleteClientModalConnectDispatchProps {
  deleteClient(): Promise<void>;
}

// you can whitelist selectors after implementation
export type DeleteClientModalConnectSelectors = Selectors;
/*
export type DeleteClientModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type DeleteClientModalConnectSelectors = Pick<Selectors, DeleteClientModalConnectSelectorsKeys>;
*/

export const mapStateToPropsDeleteClientModal: MapStateToPropsHOF<
  DeleteClientModalConnectStateProps,
  DeleteClientModalConnectOwnProps,
  AppState,
  DeleteClientModalConnectSelectors
> = (sl) => (state, { clientId }) => ({
  clientName: narrowDefined(sl.getClientById(state, clientId)).name,
});

// you can whitelist action creators after implementation
export type DeleteClientModalConnectActionCreators = ActionCreators;
/*
export type DeleteClientModalConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type DeleteClientModalConnectActionCreators = Pick<ActionCreators, DeleteClientModalConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsDeleteClientModal: MapDispatchToPropsHOF<
  DeleteClientModalConnectDispatchProps,
  DeleteClientModalConnectOwnProps,
  DeleteClientModalConnectActionCreators
> = (ac) => (dispatch, { clientId }) => ({
  deleteClient: () => dispatch(ac.clients.deactivateClient(clientId)),
});

export const connectDeleteClientModal = connect(
  mapStateToPropsDeleteClientModal(selectors),
  mapDispatchToPropsDeleteClientModal(actionCreators),
);
