import { setupSGWTConnect } from '@sgwt/connect-core';
import { ISGWTConnectIdTokenClaims } from '@sgwt/connect-core/dist/src/SGWTConnectIdTokenClaims';
import { ISGWTConnectOpenIDStandardClaims } from '@sgwt/connect-core/dist/src/SGWTConnectOpenIDUserInfo';
import { appConfig } from 'config';

const getFakeUser = () => ({
  name: 'Dow Jones',
  email: 'dow.jones@sgcib.com',
});

const getFakeSGConnect = () => {
  const standardSgConnect = getRealSGConnect();
  const fakeUser = getFakeUser();

  standardSgConnect.getIdTokenClaims = () => ({ sub: fakeUser.email } as ISGWTConnectIdTokenClaims);

  const originalFetchUserInfo = standardSgConnect.fetchUserInfo.bind(standardSgConnect);
  standardSgConnect.fetchUserInfo = callback => {
    originalFetchUserInfo((error, claims) => {
      callback(error, {
        ...claims,
        sub: fakeUser.email,
        mail: fakeUser.name,
        name: 'Dow Jones',
      } as ISGWTConnectOpenIDStandardClaims);
    });
    return standardSgConnect;
  };

  return standardSgConnect;
};

const getRealSGConnect = () =>
  setupSGWTConnect({
    authorization_endpoint: appConfig.sgConnect_authorizationEndpoint,
    client_id: appConfig.sgConnect_clientId,
    redirect_uri: window.location.origin + window.location.pathname,
    scope: appConfig.sgConnect_scopes,
    acr_values: 'L2',
  });

export const sgwtConnect = appConfig.env === 'local' ? getFakeSGConnect() : getRealSGConnect();

export const setupSgwtConnectWidget = () => {
  const widget = document.querySelector('sgwt-connect') as any;
  if (widget) {
    // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
    // `widget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        // Remove the event listener
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
};
