import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, actionCreators, selectors } from 'store';

export interface MaturitiesFilterConnectOwnProps {}

export interface MaturitiesFilterConnectStateProps {
  lowerBound: number;
  upperBound: number;
}

export interface MaturitiesFilterConnectDispatchProps {
  onLowerBoundChange(value: number): void;
  onUpperBoundChange(value: number): void;
}

// you can whitelist selectors after implementation
export type MaturitiesFilterConnectSelectors = Selectors;
/*
export type MaturitiesFilterConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type MaturitiesFilterConnectSelectors = Pick<Selectors, MaturitiesFilterConnectSelectorsKeys>;
*/

export const mapStateToPropsMaturitiesFilter: MapStateToPropsHOF<
  MaturitiesFilterConnectStateProps,
  MaturitiesFilterConnectOwnProps,
  AppState,
  MaturitiesFilterConnectSelectors
> = (sl) => (state) => ({
  lowerBound: sl.getFilter(state, 'lowerMaturityRange'),
  upperBound: sl.getFilter(state, 'upperMaturityRange'),
});

// you can whitelist action creators after implementation
export type MaturitiesFilterConnectActionCreators = ActionCreators;
/*
export type MaturitiesFilterConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type MaturitiesFilterConnectActionCreators = Pick<ActionCreators, MaturitiesFilterConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsMaturitiesFilter: MapDispatchToPropsHOF<
  MaturitiesFilterConnectDispatchProps,
  MaturitiesFilterConnectOwnProps,
  MaturitiesFilterConnectActionCreators
> = (ac) => (dispatch) => ({
  onLowerBoundChange: (value) => dispatch(ac.filters.lowerMaturityRangeChanged(value)),
  onUpperBoundChange: (value) => dispatch(ac.filters.upperMaturityRangeChanged(value)),
});

export const connectMaturitiesFilter = connect(
  mapStateToPropsMaturitiesFilter(selectors),
  mapDispatchToPropsMaturitiesFilter(actionCreators),
);
