import React from 'react';
import { Products } from 'store/displayedProducts';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connectProductFilter } from './connectProductFilter';
import { areEquivalent } from 'utils/array';

export interface ProductFilterProps {
  displayedProducts: Products[];
  onSelectProduct: (products: Products[]) => void;
}

const selectedItemArray = ['all', 'fixed', 'allPeriodicities', '3M', '6M', '12M'] as const;

type SelectedItem = typeof selectedItemArray[number];
const selectedItemToProducts: Record<SelectedItem, Products[]> = {
  all: [],
  fixed: ['fixed'],
  allPeriodicities: ['3M', '6M', '12M'],
  '3M': ['3M'],
  '6M': ['6M'],
  '12M': ['12M'],
};

export const ProductFilterRaw: React.FunctionComponent<ProductFilterProps> = ({
  displayedProducts,
  onSelectProduct,
}) => {
  const selectProductHandler = React.useCallback(
    (products: Products[]) => onSelectProduct(products),
    [onSelectProduct],
  );

  const selectedItem = convertToSelectedItem(displayedProducts);

  return (
    <div className="form-group mb-0">
      <UncontrolledDropdown>
        <DropdownToggle
          className="d-flex flex-center form-control form-control-xl btn btn-lg btn-discreet-primary dropdown-toggle text-left"
          tag="button"
          style={{ minWidth: '200px' }}
        >
          <span className="flex-grow-1">
            <FormattedMessage id={`filter.displayedProduct.${selectedItem}`} />
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <Item item="all" onSelectProduct={selectProductHandler} active={selectedItem === 'all'} />
          <DropdownItem divider />
          <Item
            item="fixed"
            onSelectProduct={selectProductHandler}
            active={selectedItem === 'fixed'}
          />
          <DropdownItem divider />
          <DropdownItem header>
            <FormattedMessage id="filter.displayedProduct.floating" />
          </DropdownItem>
          <Item
            item="allPeriodicities"
            onSelectProduct={selectProductHandler}
            active={selectedItem === 'allPeriodicities'}
          />
          <Item item="3M" onSelectProduct={selectProductHandler} active={selectedItem === '3M'} />
          <Item item="6M" onSelectProduct={selectProductHandler} active={selectedItem === '6M'} />
          <Item item="12M" onSelectProduct={selectProductHandler} active={selectedItem === '12M'} />
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

const Item: React.FunctionComponent<{
  item: SelectedItem;
  onSelectProduct: (products: Products[]) => void;
  active: boolean;
}> = ({ item, onSelectProduct, active }) => {
  const onSelectProductHandler = React.useCallback(
    () => onSelectProduct(selectedItemToProducts[item]),
    [item, onSelectProduct],
  );

  return (
    <DropdownItem className={`${active ? 'active' : ''}`} onClick={onSelectProductHandler}>
      <FormattedMessage id={`filter.displayedProduct.${item}`} />
    </DropdownItem>
  );
};

const convertToSelectedItem = (products: Products[]): SelectedItem | undefined => {
  const entry = Object.entries(selectedItemToProducts).find(e => areEquivalent(e[1], products)) as [
    SelectedItem,
    Products[],
  ];
  return entry && entry[0];
};

export const ProductFilter = connectProductFilter(ProductFilterRaw);
