export const areEquivalent = (arr1: Array<any>, arr2: Array<any>): boolean => {
  var set1 = new Set(arr1);
  var set2 = new Set(arr2);

  if (set1.size !== set2.size) {
    return false;
  }

  for (const e of set1) {
    if (!set2.has(e)) {
      return false;
    }
  }

  return true;
};

export const partition = <T>(
  arr: readonly T[],
  predicate: (item: T) => boolean,
): [pass: readonly T[], fail: readonly T[]] =>
  arr.reduce(
    (partitioned, item) => {
      partitioned[predicate(item) ? 0 : 1].push(item);
      return partitioned;
    },
    [[], []] as [pass: T[], fail: T[]],
  );
