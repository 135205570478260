import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { length } from 'utils/object';

export const isEditingMarginProfiles = (state: AppState) => state.marginProfileEdition.isEditing;

export const isSavingMarginProfiles = (state: AppState) => state.marginProfileEdition.isSaving;

export const getMarginProfileDiff = (state: AppState) => state.marginProfileEdition.diffs;

export const getMarginProfileEditionCellCount = createSelector(getMarginProfileDiff, diffs =>
  length(diffs),
);
