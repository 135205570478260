import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';
import { connect } from 'react-redux';

export interface ChangeReasonModalConnectOwnProps {}

export interface ChangeReasonModalConnectStateProps {
  isOpen: boolean;
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
  clientName: string;
  value: string | undefined;
}

export interface ChangeReasonModalConnectDispatchProps {
  toggle(): void;
  selectReason(value: string): void;
  submit(): void;
}

// you can whitelist selectors after implementation
export type ChangeReasonModalConnectSelectors = Selectors;
/*
export type ChangeReasonModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ChangeReasonModalConnectSelectors = Pick<Selectors, ChangeReasonModalConnectSelectorsKeys>;
*/

export const mapStateToPropsChangeReasonModal: MapStateToPropsHOF<
  ChangeReasonModalConnectStateProps,
  ChangeReasonModalConnectOwnProps,
  AppState,
  ChangeReasonModalConnectSelectors
> = sl => state => ({
  isOpen: sl.isClientFormReasonModalOpen(state),
  isReadyToSubmit: sl.getClientFormValue(state, 'changeReason') !== undefined,
  isSubmitting: sl.isClientFormSubmitting(state),
  clientName: sl.getClientFormValue(state, 'name')!,
  value: sl.getClientFormValue(state, 'changeReason'),
});

// you can whitelist action creators after implementation
export type ChangeReasonModalConnectActionCreators = ActionCreators;
/*
export type ChangeReasonModalConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type ChangeReasonModalConnectActionCreators = Pick<ActionCreators, ChangeReasonModalConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsChangeReasonModal: MapDispatchToPropsHOF<
  ChangeReasonModalConnectDispatchProps,
  ChangeReasonModalConnectOwnProps,
  ChangeReasonModalConnectActionCreators
> = ac => dispatch => ({
  toggle() {
    dispatch(ac.clientForm.clientFormReasonModalToggled());
    dispatch(ac.clientForm.clientFormFieldChanged({ changeReason: undefined }));
  },
  selectReason: changeReason => dispatch(ac.clientForm.clientFormFieldChanged({ changeReason })),
  submit: () => dispatch(ac.clientForm.submitClientForm()),
});

export const connectChangeReasonModal = connect(
  mapStateToPropsChangeReasonModal(selectors),
  mapDispatchToPropsChangeReasonModal(actionCreators),
);
