import { AppThunk } from 'store';
import { narrowDefined } from 'utils/asserts';

export const changeNewClientName = (newClientName: string): AppThunk => (
  dispatch,
  getState,
  { selectors: sl, actionCreators: ac },
) => {
  const state = getState();
  const foundClient = sl.getClientByName(state, newClientName);
  dispatch(
    ac.clientDuplication.clientNameChanged({
      clientName: newClientName,
      alreadyExists: foundClient !== undefined,
    }),
  );
};

export const duplicateClient = (sourceClientId: number): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
  { selectors: sl, actionCreators: ac, api },
) => {
  const state = getState();
  const newClientName = state.clientDuplication.values.newClientName;
  const sourceClient = narrowDefined(sl.getClientById(state, sourceClientId));

  dispatch(ac.clientDuplication.duplicationSubmitSent());
  const newClient = await api.clients.addClient({
    name: newClientName,
    salesMarginProfileId: sourceClient.salesMarginProfileId,
    devLMarginProfileId: sourceClient.devLMarginProfileId,
    pcruMargin: sourceClient.pcruMargin,
    lowerMaturityRange: sourceClient.lowerMaturityRange,
    upperMaturityRange: sourceClient.upperMaturityRange,
    isActive: sourceClient.isActive,
  });
  const floatingMargins = await api.floatingMargins.getClientFloatingMargins();
  dispatch(
    ac.clientDuplication.duplicationSubmitReceived({
      client: newClient,
      floatingMargins: floatingMargins,
    }),
  );
};
