import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';
import { narrowDefined } from 'utils/asserts';

export interface DuplicateClientModalConnectOwnProps {
  clientId: number;
}

export interface DuplicateClientModalConnectStateProps {
  clientName: string | undefined;
  isAlreadyExisting: boolean;
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
}

export interface DuplicateClientModalConnectDispatchProps {
  changeNewClientName(value: string): void;
  duplicateClient(): Promise<void>;
  clear(): void;
}

// you can whitelist selectors after implementation
export type DuplicateClientModalConnectSelectors = Selectors;
/*
export type DuplicateClientModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type DuplicateClientModalConnectSelectors = Pick<Selectors, DuplicateClientModalConnectSelectorsKeys>;
*/

export const mapStateToPropsDuplicateClientModal: MapStateToPropsHOF<
  DuplicateClientModalConnectStateProps,
  DuplicateClientModalConnectOwnProps,
  AppState,
  DuplicateClientModalConnectSelectors
> = (sl) => (state, { clientId }) => {
  const clientName = narrowDefined(sl.getClientById(state, clientId)).name;
  const { isAlreadyExisting, isReadyToSubmit, isSubmitting } = sl.getClientDuplicationState(state);
  return {
    clientName,
    isAlreadyExisting,
    isReadyToSubmit,
    isSubmitting,
  };
};

// you can whitelist action creators after implementation
export type DuplicateClientModalConnectActionCreators = ActionCreators;
/*
export type DuplicateClientModalConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type DuplicateClientModalConnectActionCreators = Pick<ActionCreators, DuplicateClientModalConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsDuplicateClientModal: MapDispatchToPropsHOF<
  DuplicateClientModalConnectDispatchProps,
  DuplicateClientModalConnectOwnProps,
  DuplicateClientModalConnectActionCreators
> = (ac) => (dispatch, { clientId }) => ({
  changeNewClientName: (newClientName) =>
    dispatch(ac.clientDuplication.changeNewClientName(newClientName)),
  duplicateClient: () => dispatch(ac.clientDuplication.duplicateClient(clientId)),
  clear: () => dispatch(ac.clientDuplication.duplicationCleared()),
});

export const connectDuplicateClientModal = connect(
  mapStateToPropsDuplicateClientModal(selectors),
  mapDispatchToPropsDuplicateClientModal(actionCreators),
);
