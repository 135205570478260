import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, selectors, ActionCreators, actionCreators } from 'store';

export interface ClientFormModalConnectOwnProps {}

export interface ClientFormModalConnectStateProps {
  displayMode: 'Add' | 'Edit' | 'SoftEdit';
  isModalOpen: boolean;
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
}

export interface ClientFormModalConnectDispatchProps {
  toggleModal(): void;
  submit(): void;
  clear(): void;
}

// you can whitelist selectors after implementation
export type ClientFormModalConnectSelectors = Selectors;
/*
export type ClientFormModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ClientFormModalConnectSelectors = Pick<Selectors, ClientFormModalConnectSelectorsKeys>;
*/

export const mapStateToPropsClientFormModal: MapStateToPropsHOF<
  ClientFormModalConnectStateProps,
  ClientFormModalConnectOwnProps,
  AppState,
  ClientFormModalConnectSelectors
> = sl => state => {
  const { displayMode, isModalOpen, isReadyToSubmit, isSubmitting } = sl.getClientForm(state);
  return {
    displayMode,
    isModalOpen,
    isReadyToSubmit,
    isSubmitting,
  };
};

// you can whitelist action creators after implementation
export type ClientFormModalConnectActionCreators = ActionCreators;
/*
export type ClientFormModalConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type ClientFormModalConnectActionCreators = Pick<ActionCreators, ClientFormModalConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsClientFormModal: MapDispatchToPropsHOF<
  ClientFormModalConnectDispatchProps,
  ClientFormModalConnectOwnProps,
  ClientFormModalConnectActionCreators
> = ac => dispatch => ({
  toggleModal() {
    dispatch(ac.clientForm.clientFormModalClosed());
  },
  submit() {
    dispatch(ac.clientForm.submitClientForm());
  },
  clear() {
    dispatch(ac.clientForm.clientFormCleared());
  },
});

export const connectClientFormModal = connect(
  mapStateToPropsClientFormModal(selectors),
  mapDispatchToPropsClientFormModal(actionCreators),
);
