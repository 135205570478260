import { Fetch, ensureSuccessStatusCode } from './utils';

export interface MarginProfilesApi {
  getMarginProfiles(): Promise<BackendMarginProfile[]>;
  updateMargins(payload: UpdateMarginsPayload): Promise<void>;
}

export type BackendMarginProfile = BackendMarginProfileSale | BackendMarginProfileDevL;

interface BackendMarginProfileBase {
  id: number;
  name: string;
  isPreset: boolean;
}

export interface BackendMarginProfileSale extends BackendMarginProfileBase {
  type: 'Sales';
  fixedMargins: MarginByMaturity[];
  floating3M: MarginByMaturity[];
  floating6M: MarginByMaturity[];
  floating12M: MarginByMaturity[];
}

export interface BackendMarginProfileDevL extends BackendMarginProfileBase {
  type: 'DevL';
  margins: MarginByMaturity[];
}

export interface BackendMarginWithoutProfile extends BackendMarginProfileBase {
  type: 'NA';
  techMarginsFixed: MarginByMaturity[];
  techMarginsFloating: MarginByMaturity[];
  adjustmentMargins: MarginByMaturity[];
}

interface MarginByMaturity {
  id: string;
  maturity: string;
  value: number;
}

export type UpdateMarginsPayload = Pick<MarginByMaturity, 'id' | 'value'>[];

export const marginProfileApi = (fetch: Fetch): MarginProfilesApi => ({
  getMarginProfiles: async () => {
    const res = await fetch('/api/marginProfiles');
    ensureSuccessStatusCode(res);
    return await res.json();
  },
  updateMargins: async payload => {
    const res = await fetch('/api/marginProfiles/margins', {
      method: 'PATCH',
      body: JSON.stringify(payload),
    });
    ensureSuccessStatusCode(res);
  },
});
