import React, { useState, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from 'components/shared/LoadingButton';
import { connectDeleteClientModal } from './connectDeleteClientModal';

export interface DeleteClientModalProps {
  isOpen: boolean;
  clientId: number;
  clientName: string;
  toggle(): void;
  deleteClient(): Promise<void>;
}

const DeleteClientModalRaw: React.FunctionComponent<DeleteClientModalProps> = ({
  isOpen,
  clientName,
  toggle,
  deleteClient,
}) => {
  const [isLoading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    setLoading(true);
    await deleteClient();
    setLoading(false);
    toggle();
  }, [setLoading, deleteClient, toggle]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="border border-warning">
      <ModalHeader tag="h3" className="text-warning" toggle={toggle}>
        <FormattedMessage id="deleteClientModal.title" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id="deleteClientModal.description"
          values={{
            client: clientName,
            strong: (txt: string) => <strong className="text-secondary">{txt}</strong>,
          }}
        />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-lg btn-flat-secondary" onClick={toggle}>
          <FormattedMessage id="common.cancel" />
        </button>
        <LoadingButton
          className="btn btn-lg btn-warning"
          loading={isLoading}
          onClick={handleDelete}
        >
          <FormattedMessage id="common.delete" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export const DeleteClientModal = connectDeleteClientModal(DeleteClientModalRaw);
