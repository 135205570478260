import { connect } from 'react-redux';
import { MapStateToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, selectors } from 'store';

export interface TimeoutModalConnectOwnProps {}

export interface TimeoutModalConnectStateProps {
  isOpen: boolean;
}

// you can whitelist selectors after implementation
export type TimeoutModalConnectSelectors = Selectors;
/*
export type TimeoutModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type TimeoutModalConnectSelectors = Pick<Selectors, TimeoutModalConnectSelectorsKeys>;
*/

export const mapStateToPropsTimeoutModal: MapStateToPropsHOF<
  TimeoutModalConnectStateProps,
  TimeoutModalConnectOwnProps,
  AppState,
  TimeoutModalConnectSelectors
> = sl => state => ({
  isOpen: sl.hasAuthExpired(state),
});

export const connectTimeoutModal = connect(mapStateToPropsTimeoutModal(selectors));
