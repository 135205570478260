import { connect } from 'react-redux';
import { MapDispatchToPropsHOF } from 'typings/redux-connect';
import { ActionCreators, actionCreators } from 'store';

export interface AccountPickerConnectOwnProps {}

export interface AccountPickerConnectStateProps {}

export interface AccountPickerConnectDispatchProps {
  onChange(value: string): void;
}

export type AccountPickerConnectActionCreators = ActionCreators;

export const mapDispatchToPropsAccountPicker: MapDispatchToPropsHOF<
  AccountPickerConnectDispatchProps,
  AccountPickerConnectOwnProps,
  AccountPickerConnectActionCreators
> = ac => dispatch => ({
  onChange: (value: string) => dispatch(ac.clientForm.changeClientName(value)),
});

export const connectAccountPicker = connect(
  undefined,
  mapDispatchToPropsAccountPicker(actionCreators),
);
