import { FilterGroup } from 'components/ClientsMargins/Filters/FilterGroups/FilterGroup';
import {
  ProductFilterProps,
  ProductFilterRaw,
} from 'components/ClientsMargins/Filters/ProductFilter';
import React from 'react';

export const ProductFilter: React.FunctionComponent<ProductFilterProps> = (props) => (
  <FilterGroup label="Type of product">
    <ProductFilterRaw {...props} />
  </FilterGroup>
);
