import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { Selectors, selectors, actionCreators } from 'store';
import { ActionCreators } from 'store';
import { AppState } from 'store';

export interface CustomOnlyFilterConnectOwnProps {}

export interface CustomOnlyFilterConnectStateProps {
  value: boolean;
}

export interface CustomOnlyFilterConnectDispatchProps {
  onToggle(): void;
}

// you can whitelist selectors after implementation
export type CustomOnlyFilterConnectSelectors = Selectors;
/*
export type CustomOnlyFilterConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type CustomOnlyFilterConnectSelectors = Pick<Selectors, CustomOnlyFilterConnectSelectorsKeys>;
*/

export const mapStateToPropsCustomOnlyFilter: MapStateToPropsHOF<
  CustomOnlyFilterConnectStateProps,
  CustomOnlyFilterConnectOwnProps,
  AppState,
  CustomOnlyFilterConnectSelectors
> = sl => state => ({
  value: sl.getFilter(state, 'customizedMarginsOnly'),
});

// you can whitelist action creators after implementation
export type CustomOnlyFilterConnectActionCreators = ActionCreators;
/*
export type CustomOnlyFilterConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type CustomOnlyFilterConnectActionCreators = Pick<ActionCreators, CustomOnlyFilterConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsCustomOnlyFilter: MapDispatchToPropsHOF<
  CustomOnlyFilterConnectDispatchProps,
  CustomOnlyFilterConnectOwnProps,
  CustomOnlyFilterConnectActionCreators
> = ac => dispatch => ({
  onToggle() {
    dispatch(ac.filters.customizedMarginsOnlyFilterToggled());
  },
});

export const connectCustomOnlyFilter = connect(
  mapStateToPropsCustomOnlyFilter(selectors),
  mapDispatchToPropsCustomOnlyFilter(actionCreators),
);
