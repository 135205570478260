import { AppState } from 'store';
import { ClientForm } from './clientFormSlice';

export const getClientForm = (state: AppState) => state.clientForm;

export const getClientFormValues = (state: AppState) => getClientForm(state).values;

export const isClientFormReasonModalOpen = (state: AppState) =>
  getClientForm(state).isReasonModalOpen;

export const isClientFormSubmitting = (state: AppState) => getClientForm(state).isSubmitting;

export const getClientFormValue = <Key extends keyof ClientForm>(
  state: AppState,
  key: Key,
): ClientForm[Key] => getClientFormValues(state)[key];
