import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connectEditMarginPresets } from './connect/connectEditMarginPresets';
import { LoadingButton } from 'components/shared/LoadingButton';

export interface EditMarginPresetsProps {
  isEditing: boolean;
  isSaving: boolean;
  editedCellsCount: number;
  toggleEdit(): void;
  save(): void;
}

const EditMarginsPresetsRaw: React.FunctionComponent<EditMarginPresetsProps> = ({
  isEditing,
  isSaving,
  editedCellsCount,
  toggleEdit,
  save,
}) =>
  isEditing ? (
    <div className="d-flex align-items-center alert alert-info px-3 py-1">
      <div className="flex-fill">
        <span>
          <FormattedMessage id="common.cellsEdited" values={{ editedCellsCount }} />
        </span>
      </div>
      <div>
        <button className="btn btn-info m-1" disabled={isSaving} onClick={toggleEdit}>
          <FormattedMessage id="common.cancel" />
        </button>
        <LoadingButton
          className="btn btn-outline-white m-1"
          loading={isSaving}
          disabled={editedCellsCount === 0 || isSaving}
          onClick={save}
        >
          <FormattedMessage id="clientsMargins.edit.saveChanges" />
        </LoadingButton>
      </div>
    </div>
  ) : (
    <div className="d-flex flex-row-reverse align-items-center my-3">
      <button className="btn btn-primary btn-icon-text" onClick={toggleEdit}>
        <i className="icon">edit</i>
        <FormattedMessage id="common.edit" />
      </button>
    </div>
  );

export const EditMarginPresets = connectEditMarginPresets(EditMarginsPresetsRaw);
