import { selectors, actionCreators } from 'store';
import { connect } from 'react-redux';

import {
  mapStateToPropsProfileFilter,
  mapDispatchToPropsProfileFilter,
} from './connect/connectProfileFilter';
import { Filter } from './Filter';

import {
  mapStateToPropsPcruFilter,
  mapDispatchToPropsPcruFilter,
} from './connect/connectPcruFilter';

export const ProfileFilter = connect(
  mapStateToPropsProfileFilter(selectors),
  mapDispatchToPropsProfileFilter(actionCreators),
)(Filter);

export const PcruFilter = connect(
  mapStateToPropsPcruFilter(selectors),
  mapDispatchToPropsPcruFilter(actionCreators),
)(Filter);
