import { useFeatureToggles } from 'components/hooks/useFeatureToggles';
import React, { useMemo } from 'react';
import { ActiveClient } from './ActiveClient/ActiveClient';
import { ActiveFilters } from './ActiveFilters';
import { ClientSearch } from './ClientSearch';
import {
  CustomOnlyFilter,
  DevLProfileFilters,
  PcruFilters,
  SalesProfileFilters,
} from './FilterGroups';
import { MaturitiesFilter } from './Maturities';
import { ProductFilter } from './ProductFilter';

export interface ClientsMarginsFiltersProps {}

export const ClientsMarginsFilters: React.FunctionComponent<ClientsMarginsFiltersProps> = () => {
  const features = useFeatureToggles();
  const clientSearchStyle = useMemo(() => ({ maxWidth: '1200px' }), []);
  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1" style={clientSearchStyle}>
          <ClientSearch />
        </div>
        {!features.capFlooredActivated && (
          <div className="ml-4">
            <ProductFilter />
          </div>
        )}
        <div className="ml-4">
          <ActiveClient />
        </div>
      </div>
      <div className="d-flex mb-3">
        <MaturitiesFilter />
        <CustomOnlyFilter />
        <SalesProfileFilters />
        <DevLProfileFilters />
        <PcruFilters />
      </div>
      <ActiveFilters />
    </>
  );
};
