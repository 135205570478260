import { Middleware } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { serializeError } from 'utils/error';
import { errorSlice } from './errorSlice';

export const catchMiddleware: Middleware<{}, AppState> = store => next => async action => {
  try {
    return await next(action);
  } catch (error) {
    next(errorSlice.actions.appCrashed(serializeError(error)));
  }
};
