import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { strictKeys, strictValues, strictEntries } from 'utils/object';

export const getClientEditionState = (state: AppState) => state.clientEdition;

export const isEditingClients = (state: AppState) => getClientEditionState(state).isEditing;

export const isSavingClientEdition = (state: AppState) => getClientEditionState(state).isSaving;

export const isClientEditionReasonModalOpen = (state: AppState) =>
  getClientEditionState(state).isReasonModalOpen;

export const getEditedClientsDiffs = (state: AppState) => getClientEditionState(state).diffs;

export const getEditedClientsIds = createSelector(getEditedClientsDiffs, strictKeys);

export const hasClientEditionMissingChangeReasons = createSelector(getEditedClientsDiffs, diffs =>
  strictValues(diffs).some(
    diff => diff.salesMarginProfileId !== undefined && diff.changeReason === undefined,
  ),
);

export const getEditedCells = createSelector([getEditedClientsDiffs], diffs =>
  strictValues(diffs).reduce(
    (sum, diff) =>
      sum +
      strictEntries(diff).filter(([key, value]) => key !== 'changeReason' && value !== undefined)
        .length *
        30,
    0,
  ),
);
