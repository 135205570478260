import { getMatomo } from 'bootstrap/matomo';
import { MarginsPresetsTable } from 'components/MarginsPresets/MarginsPresetsTable';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ReadonlyMarginPresetsProps {
  edit: () => void;
  close: () => void;
}

export const ReadonlyMarginPresets: React.FunctionComponent<ReadonlyMarginPresetsProps> = ({
  edit,
  close,
}) => {
  useEffect(() => {
    const matomo = getMatomo();
    matomo.track('Clients margins', 'Presets preview opened');
    return () => {
      matomo.track('Clients margins', 'Presets preview closed');
    };
  }, []);
  return (
    <div className="fixed-bottom card border-top visible bg-lvl2 scroll" style={{ height: 430 }}>
      <div className="d-flex align-items-center pt-3 pb-3 pl-3 pr-3">
        <div className="flex-fill">
          <h4>
            <FormattedMessage id="clientsMargins.marginsPresets" />
          </h4>
        </div>
        <button className="btn sgbs-btn-default" onClick={edit}>
          <FormattedMessage id="clientsMargins.updateMarginPresets" />
        </button>
        <button onClick={close} type="button" className="btn btn-xl">
          <i className="icon">close</i>
        </button>
      </div>
      <div className="mx-4 h-100">
        <MarginsPresetsTable />
      </div>
    </div>
  );
};
