import { Middleware } from '@reduxjs/toolkit';
import { SGWTConnectCore, EVENT_AUTH_EXPIRED } from '@sgwt/connect-core';
import { AppState } from 'store';
import { actionCreators } from 'store/actions';

export const sgwtMiddleware = (sgwt: SGWTConnectCore): Middleware<{}, AppState> => store => {
  sgwt.on(EVENT_AUTH_EXPIRED, () => store.dispatch(actionCreators.ui.authExpired()));

  return next => action => next(action);
};
