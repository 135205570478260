import React, { ChangeEventHandler, useCallback } from 'react';

export interface CheckboxProps {
  id: string;
  label: string;
  value: boolean;
  onChange(value: boolean): void;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  id,
  label,
  value,
  onChange,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ev => onChange(ev.target.checked),
    [onChange],
  );
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        checked={value}
        id={id}
        className="custom-control-input"
        onChange={handleChange}
      />
      <label htmlFor={id} className="custom-control-label">
        {label}
      </label>
    </div>
  );
};
