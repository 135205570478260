import { AppThunk } from 'store';

export const fetchUserData = (): AppThunk<Promise<void>> => async (
  dispatch,
  _getState,
  { api, actionCreators },
) => {
  dispatch(actionCreators.userData.userDataRequested());
  const userData = await api.userData.getUserData();
  dispatch(actionCreators.userData.userDataReceived(userData));
};
