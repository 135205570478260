import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connectEditClientsMargins } from './connectEditClientsMargin';
import { ChangeReasonsModal } from 'components/ChangeReasonModal';
import { LoadingButton } from 'components/shared/LoadingButton';

export interface EditClientsMarginsProp {
  isEditing: boolean;
  isSaving: boolean;
  hasClients: boolean;
  editedCellsCount: number;
  toggle: () => void;
  previewPreset: () => void;
  closePreset: () => void;
  save: () => void;
}

const EditClientsMarginsRaw: React.FunctionComponent<EditClientsMarginsProp> = ({
  isEditing,
  isSaving,
  hasClients,
  editedCellsCount,
  toggle,
  previewPreset,
  closePreset,
  save,
}) => {
  const cancelEditing = React.useCallback(() => {
    toggle();
    closePreset();
  }, [toggle, closePreset]);

  const saveEditing = React.useCallback(() => {
    save();
    closePreset();
  }, [save, closePreset]);

  return (
    <>
      <ChangeReasonsModal />
      {!isEditing ? (
        !hasClients ? null : (
          <div className="d-flex flex-row-reverse align-items-center mb-3">
            <button className="btn btn-primary btn-icon-text" onClick={toggle}>
              <i className="icon">edit</i>
              <FormattedMessage id="common.edit" />
            </button>
          </div>
        )
      ) : (
        <div className="d-flex align-items-center alert alert-info px-3 py-1">
          <div className="flex-fill">
            <span>
              <FormattedMessage id="common.cellsEdited" values={{ editedCellsCount }} />
            </span>
          </div>
          <div>
            <button className="btn btn-info m-1" onClick={cancelEditing}>
              <FormattedMessage id="common.cancel" />
            </button>
            <button
              onClick={previewPreset}
              className="btn btn-discreet-light text-white btn-icon-text m-1"
            >
              <i className="icon">remove_red_eye</i>
              <FormattedMessage id="clientsMargins.edit.previewPresets" />
            </button>
            <LoadingButton
              className="btn btn-outline-white m-1"
              loading={isSaving}
              disabled={editedCellsCount === 0}
              onClick={saveEditing}
            >
              <FormattedMessage id="clientsMargins.edit.saveChanges" />
            </LoadingButton>
          </div>
        </div>
      )}
    </>
  );
};

export const EditClientsMargins = connectEditClientsMargins(EditClientsMarginsRaw);
