import React, { useCallback } from 'react';

export interface FilterProps {
  label: string | undefined;
  active: boolean;
  toggle: () => void;
}

export const Filter: React.FunctionComponent<FilterProps> = ({ label, active, toggle }) => {
  const handleToggle: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    ev => {
      toggle();
      ev.currentTarget.blur(); // focused styled while toggling looks confusing to users
    },
    [toggle],
  );
  return (
    <button
      type="button"
      className={`btn btn-toggle-primary ${active ? 'active' : ''} mr-1`}
      onClick={handleToggle}
    >
      {label}
    </button>
  );
};
