import { ColumnApi, GridApi, RowGroupOpenedEvent, RowNode } from 'ag-grid-community';
import { BaseColDefParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { useFeatureToggles } from 'components/hooks/useFeatureToggles';
import { AgGridReactWithDefaults } from 'components/shared/agGrid/AgGridReactWithDefaults';
import { TableContainer } from 'components/shared/agGrid/TableContainer';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { ClientDiff, ClientsDiffs } from 'store/clientEdition';
import { Client } from 'store/clients';
import { Products } from 'store/displayedProducts';
import { FloatingMargin } from 'store/floatingMargins';
import { MarginProfile } from 'store/marginProfiles';
import { partition } from 'utils/array';
import { range } from 'utils/range';
import { connectClientsMarginsTable } from './connectClientsMarginsTable';
import {
  getColumnDef,
  getMarginTableRowNodeId,
  getMaturityColId,
  getRowData,
  MarginsTableRow,
} from './dataToAggrid';

export interface ClientsMarginsTableProps {
  clients: readonly Client[];
  marginProfiles: Record<number, MarginProfile>;
  floatingMargins: Record<string, FloatingMargin>;
  isEditing: boolean;
  clientsDiffs: ClientsDiffs;
  displayedProducts: Products[];
  lowerMaturityRange: number;
  upperMaturityRange: number;
  modifyMarginProfile(clientId: number, diff: Partial<ClientDiff>): void;
  editClient(clientId: number): void;
  duplicateClient(clientId: number): void;
  deleteClient(clientId: number): void;
}

export interface ClientsMarginsTableContext {
  isEditing: boolean;
  displayedProducts: Products[];
}

const getMaturityTotalRowClass = (params: BaseColDefParams) =>
  params.node.group ? 'font-weight-bold' : '';

// This is used to hide the label "Customized" when the user expands the client's margins.
const onRowGroupOpened = ({ api, node }: RowGroupOpenedEvent) => {
  api.refreshCells({
    rowNodes: [node],
    columns: ['marginProfileName'],
    force: true,
  });
};

const isExternalFilterPresent = (displayedProductsRef: React.MutableRefObject<Products[]>) => () =>
  displayedProductsRef.current.length > 0;

const doesExternalFilterPass = (displayedProductsRef: React.MutableRefObject<Products[]>) => (
  node: RowNode,
): boolean => {
  const displayedProducts = displayedProductsRef.current;
  const tableRow = node.data as MarginsTableRow;

  switch (tableRow.marginProfile.type) {
    case 'Sales':
      return displayedProducts.includes('fixed');
    case 'totalFloating3M':
      return displayedProducts.includes('3M');
    case 'totalFloating6M':
      return displayedProducts.includes('6M');
    case 'totalFloating12M':
      return displayedProducts.includes('12M');
    default:
      return true;
  }
};

const ClientsMarginsTableRaw: React.FunctionComponent<ClientsMarginsTableProps> = ({
  clients,
  marginProfiles,
  floatingMargins,
  isEditing,
  clientsDiffs,
  displayedProducts,
  lowerMaturityRange,
  upperMaturityRange,
  modifyMarginProfile,
  editClient,
  duplicateClient,
  deleteClient,
}) => {
  const { formatMessage } = useIntl();
  const features = useFeatureToggles();

  const agGridApi = React.useRef<{ api: GridApi; columnApi: ColumnApi } | undefined>();
  const displayedProductsRef = React.useRef<Products[]>(displayedProducts);

  const context = useRef<ClientsMarginsTableContext>({ isEditing, displayedProducts });

  // update editing state
  React.useEffect(() => {
    context.current.isEditing = isEditing;
    agGridApi.current?.api.refreshCells({ force: true });
    if (!isEditing) {
      agGridApi.current?.api.stopEditing(true);
    }
  }, [isEditing]);

  // update displayed products
  React.useEffect(() => {
    context.current.displayedProducts = displayedProducts;
    agGridApi.current?.api.refreshCells({ force: true });
  }, [displayedProducts]);

  // update visible maturity range
  React.useEffect(() => {
    const [visible, hidden] = partition(
      range(1, 30),
      (cur) => cur >= lowerMaturityRange && cur <= upperMaturityRange,
    );
    agGridApi.current?.columnApi.setColumnsVisible(visible.map(getMaturityColId), true);
    agGridApi.current?.columnApi.setColumnsVisible(hidden.map(getMaturityColId), false);
    agGridApi.current?.api.sizeColumnsToFit();
  }, [lowerMaturityRange, upperMaturityRange]);

  // update row filtering
  React.useEffect(() => {
    displayedProductsRef.current = displayedProducts;
    agGridApi.current?.api.onFilterChanged();
  }, [displayedProducts]);

  // update margin profiles. WARNING: this is only used to refresh labels in group row which are not updated when the children data changes.
  // If we could target a specific group row it would be better.
  React.useEffect(() => {
    agGridApi.current?.api.refreshCells({ columns: ['marginProfileName'] });
  }, [clients]);

  const columnDef = getColumnDef(
    formatMessage,
    marginProfiles,
    modifyMarginProfile,
    editClient,
    duplicateClient,
    deleteClient,
    features,
  );

  const rowData = getRowData(clients, marginProfiles, floatingMargins, clientsDiffs, features);

  return (
    <TableContainer>
      <AgGridReactWithDefaults
        agGridApiRef={agGridApi}
        columnDefs={columnDef}
        rowData={rowData}
        context={context.current}
        onRowGroupOpened={onRowGroupOpened}
        getRowClass={getMaturityTotalRowClass}
        groupDefaultExpanded={0}
        deltaRowDataMode={true}
        getRowNodeId={getMarginTableRowNodeId}
        componentWrappingElement="span"
        isExternalFilterPresent={isExternalFilterPresent(displayedProductsRef)}
        doesExternalFilterPass={doesExternalFilterPass(displayedProductsRef)}
      />
    </TableContainer>
  );
};

export const ClientsMarginsTable = connectClientsMarginsTable(ClientsMarginsTableRaw);
