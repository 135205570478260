import { connect } from 'react-redux';
import { ActionCreators, actionCreators, AppState, Selectors, selectors } from 'store';
import { MapDispatchToPropsHOF, MapStateToPropsHOF } from 'typings/redux-connect';

interface EditClientsMarginsConnectOwnProps {}

interface EditClientsMarginsConnectStateProps {
  isEditing: boolean;
  isSaving: boolean;
  hasClients: boolean;
  editedCellsCount: number;
}

interface EditClientsMarginsConnectDispatchProps {
  toggle: () => void;
  save: () => void;
}

// you can whitelist selectors after implementation
type EditClientsMarginsConnectSelectors = Selectors;
/*
type EditClientsMarginsConnectSelectorsKeys = 'getOptionLegOptionType'; // example
type EditClientsMarginsConnectSelectors = Pick<Selectors, EditClientsMarginsConnectSelectorsKeys>;
*/

const mapStateToPropsEditClientsMargins: MapStateToPropsHOF<
  EditClientsMarginsConnectStateProps,
  EditClientsMarginsConnectOwnProps,
  AppState,
  EditClientsMarginsConnectSelectors
> = (sl) => (state) => ({
  isEditing: sl.isEditingClients(state),
  isSaving: sl.isSavingClientEdition(state),
  editedCellsCount: sl.getEditedCells(state),
  hasClients: sl.getFilteredClients(state).length > 0,
});

// you can whitelist action creators after implementation
type EditClientsMarginsConnectActionCreators = ActionCreators;
/*
export type EditClientsMarginConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type EditClientsMarginConnectActionCreators = Pick<ActionCreators, EditClientsMarginConnectActionCreatorsKeys>;
*/

const mapDispatchToPropsEditClientsMargins: MapDispatchToPropsHOF<
  EditClientsMarginsConnectDispatchProps,
  EditClientsMarginsConnectOwnProps,
  EditClientsMarginsConnectActionCreators
> = (ac) => (dispatch) => ({
  toggle: () => dispatch(ac.clientEdition.toggleEdition()),
  save: () => dispatch(ac.clientEdition.saveChanges()),
});

export const connectEditClientsMargins = connect(
  mapStateToPropsEditClientsMargins(selectors),
  mapDispatchToPropsEditClientsMargins(actionCreators),
);
