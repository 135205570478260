import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ErrorState = {
  hasError: boolean;
  error: string | undefined;
};

const initialState: ErrorState = {
  hasError: false,
  error: undefined,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    appCrashed: (state, { payload: error }: PayloadAction<Error>) => {
      state.hasError = true;
      state.error = `${error.stack ?? error ?? 'internal error'}`;
    },
  },
});
