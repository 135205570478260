import React from 'react';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { IcAccountPicker } from 'components/shared/IcAccountPicker';
import { OverflowableModalBody } from 'components/shared/Modal/OverflowableModalBody';
import { connectDuplicateClientModal } from './connectDuplicateClientModal';
import { LoadingButton } from 'components/shared/LoadingButton';

export interface DuplicateClientModalProps {
  isOpen: boolean;
  isAlreadyExisting: boolean;
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
  clientId: number | undefined;
  clientName: string | undefined;
  toggle(): void;
  changeNewClientName(value: string): void;
  duplicateClient(): Promise<void>;
  clear(): void;
}

const DuplicateClientModalRaw: React.FunctionComponent<DuplicateClientModalProps> = ({
  isOpen,
  clientName,
  isAlreadyExisting,
  isReadyToSubmit,
  isSubmitting,
  toggle,
  changeNewClientName,
  duplicateClient,
  clear,
}) => {
  const handleDuplicate = async () => {
    await duplicateClient();
    toggle();
  };
  return (
    <Modal isOpen={isOpen} onClosed={clear}>
      <ModalHeader tag="h3" toggle={toggle}>
        <FormattedMessage id="duplicateClientModal.title" />
      </ModalHeader>
      <OverflowableModalBody>
        <p>
          <FormattedMessage
            id="duplicateClientModal.description"
            values={{
              client: clientName,
              strong: (msg: string) => <strong className="text-secondary">{msg}</strong>,
            }}
          />
        </p>
        <div className="form-group">
          <label>
            <FormattedMessage id="duplicateClientModal.newClientName" />
          </label>
          <IcAccountPicker onChange={changeNewClientName} />
          {isAlreadyExisting && (
            <p className="d-flex align-items-center justify-content-between py-3 text-info">
              <span>
                <i className="icon icon-sm mr-2">info_outline</i>
                <FormattedMessage id="clientForm.clientAlreadyAdded" />
              </span>
              <button className="btn btn-outline-info">Go to client's margins</button>
            </p>
          )}
        </div>
      </OverflowableModalBody>
      <ModalFooter>
        <button className="btn btn-lg btn-flat-secondary" onClick={toggle}>
          <FormattedMessage id="common.cancel" />
        </button>
        <LoadingButton
          className="btn btn-lg btn-primary"
          disabled={!isReadyToSubmit || isSubmitting}
          loading={isSubmitting}
          onClick={handleDuplicate}
        >
          <FormattedMessage id="common.add" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export const DuplicateClientModal = connectDuplicateClientModal(DuplicateClientModalRaw);
