import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';
import { connect } from 'react-redux';

export interface EditMarginPresetsConnectOwnProps {}

export interface EditMarginPresetsConnectStateProps {
  isEditing: boolean;
  isSaving: boolean;
  editedCellsCount: number;
}

export interface EditMarginPresetsConnectDispatchProps {
  toggleEdit(): void;
  save(): void;
}

// you can whitelist selectors after implementation
export type EditMarginPresetsConnectSelectors = Selectors;
/*
export type EditMarginPresetsConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type EditMarginPresetsConnectSelectors = Pick<Selectors, EditMarginPresetsConnectSelectorsKeys>;
*/

export const mapStateToPropsEditMarginPresets: MapStateToPropsHOF<
  EditMarginPresetsConnectStateProps,
  EditMarginPresetsConnectOwnProps,
  AppState,
  EditMarginPresetsConnectSelectors
> = sl => state => ({
  isEditing: sl.isEditingMarginProfiles(state),
  isSaving: sl.isSavingMarginProfiles(state),
  editedCellsCount: sl.getMarginProfileEditionCellCount(state),
});

// you can whitelist action creators after implementation
export type EditMarginPresetsConnectActionCreators = ActionCreators;
/*
export type EditMarginPresetsConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type EditMarginPresetsConnectActionCreators = Pick<ActionCreators, EditMarginPresetsConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsEditMarginPresets: MapDispatchToPropsHOF<
  EditMarginPresetsConnectDispatchProps,
  EditMarginPresetsConnectOwnProps,
  EditMarginPresetsConnectActionCreators
> = ac => dispatch => ({
  toggleEdit: () => dispatch(ac.marginProfileEdition.editionToggled()),
  save: () => dispatch(ac.marginProfileEdition.saveEdition()),
});

export const connectEditMarginPresets = connect(
  mapStateToPropsEditMarginPresets(selectors),
  mapDispatchToPropsEditMarginPresets(actionCreators),
);
