import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxProps, Checkbox } from 'components/shared/Form/Checkbox';
import { connectIsActive } from './connect/connectIsActive';

export type IsActiveProps = Pick<CheckboxProps, 'value' | 'onChange'>;

const IsActiveRaw: React.FunctionComponent<IsActiveProps> = ({ value, onChange }) => {
  return (
    <FormattedMessage id="clientForm.activeClient">
      {(msg: string) => <Checkbox id="isActive" label={msg} value={value} onChange={onChange} />}
    </FormattedMessage>
  );
};

export const IsActive = connectIsActive(IsActiveRaw);
