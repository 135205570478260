import React from 'react';

export const FilterGroup: React.FunctionComponent<{ label: string }> = ({ label, children }) => (
  <div className="d-flex flex-column pr-4">
    <p className="text-secondary font-weight-bolder mb-2">{label}</p>
    <div className="btn-group btn-group-toggle flex-grow-1 d-flex align-items-center">
      {children}
    </div>
  </div>
);
