import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';

export interface ChangeReasonsModalConnectOwnProps {}

export interface ChangeReasonsModalConnectStateProps {
  isOpen: boolean;
  clientIds: number[];
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
}

export interface ChangeReasonsModalConnectDispatchProps {
  toggle(): void;
  submit(): void;
}

// you can whitelist selectors after implementation
export type ChangeReasonsModalConnectSelectors = Selectors;
/*
export type ChangeReasonsModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ChangeReasonsModalConnectSelectors = Pick<Selectors, ChangeReasonsModalConnectSelectorsKeys>;
*/

export const mapStateToPropsChangeReasonsModal: MapStateToPropsHOF<
  ChangeReasonsModalConnectStateProps,
  ChangeReasonsModalConnectOwnProps,
  AppState,
  ChangeReasonsModalConnectSelectors
> = sl => (state, _ownProps) => ({
  isOpen: sl.isClientEditionReasonModalOpen(state),
  clientIds: sl.getEditedClientsIds(state),
  isReadyToSubmit: !sl.hasClientEditionMissingChangeReasons(state),
  isSubmitting: sl.isSavingClientEdition(state),
});

// you can whitelist action creators after implementation
export type ChangeReasonsModalConnectActionCreators = ActionCreators;
/*
export type ChangeReasonsModalConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type ChangeReasonsModalConnectActionCreators = Pick<ActionCreators, ChangeReasonsModalConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsChangeReasonsModal: MapDispatchToPropsHOF<
  ChangeReasonsModalConnectDispatchProps,
  ChangeReasonsModalConnectOwnProps,
  ChangeReasonsModalConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  toggle: () => dispatch(ac.clientEdition.reasonModalToggled()),
  submit: () => dispatch(ac.clientEdition.saveChanges()),
});

export const connectChangeReasonsModal = connect(
  mapStateToPropsChangeReasonsModal(selectors),
  mapDispatchToPropsChangeReasonsModal(actionCreators),
);
