import { createWmcApi } from 'api';
import { createStore } from 'store';
import { getMatomo } from './matomo';
import { sgwtConnect } from './sgwtConnect';

const api = createWmcApi();

export const store = createStore(api, sgwtConnect, getMatomo());

export const initStore = () => {
  store.dispatch(async (dispatch, _getState, { selectors: sl, actionCreators: ac }) => {
    await Promise.all([
      dispatch(ac.clients.fetchClients()),
      dispatch(ac.marginProfiles.fetchMarginProfiles()),
      dispatch(ac.floatingMargins.fetchFloatingMargins()),
      dispatch(ac.userData.fetchUserData()),
    ]);
    dispatch(ac.ui.appBootstrapped(sgwtConnect.getIdTokenClaims()?.sub!));
  });
};
