import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connectChangeReasonsModal } from './connectChangeReasonsModal';
import { SelectReason } from './SelectReason';
import { LoadingButton } from 'components/shared/LoadingButton';

export interface ChangeReasonsModalProps {
  isOpen: boolean;
  clientIds: number[];
  isReadyToSubmit: boolean;
  isSubmitting: boolean;
  toggle(): void;
  submit(): void;
}

const ChangeReasonsModalRaw: React.FunctionComponent<ChangeReasonsModalProps> = ({
  isOpen,
  clientIds,
  isReadyToSubmit,
  isSubmitting,
  toggle,
  submit,
}) => (
  <Modal isOpen={isOpen}>
    <ModalHeader tag="h3" toggle={toggle}>
      <FormattedMessage id="changeReasonModal.title" />
    </ModalHeader>
    <ModalBody>
      {clientIds.map(id => (
        <SelectReason key={id} clientId={id} />
      ))}
    </ModalBody>
    <ModalFooter>
      <button className="btn btn-lg btn-flat-secondary" onClick={toggle}>
        <FormattedMessage id="common.cancel" />
      </button>
      <LoadingButton
        className="btn btn-lg btn-primary"
        onClick={submit}
        loading={isSubmitting}
        disabled={!isReadyToSubmit || isSubmitting}
      >
        <FormattedMessage id="common.save" />
      </LoadingButton>
    </ModalFooter>
  </Modal>
);

export const ChangeReasonsModal = connectChangeReasonsModal(ChangeReasonsModalRaw);
