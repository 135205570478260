import { Fetch, ensureSuccessStatusCode } from './utils';
import { MarginByMaturity } from 'store/marginProfiles';
import { backendToStoreFloatingMargin } from 'store/floatingMargins/floatingMarginsUtils';
import { FloatingMargin } from 'store/floatingMargins';

export interface ClientFloatingMarginsApi {
  getClientFloatingMargins(): Promise<FloatingMargin[]>;
}

export interface BackendFloatingMargin {
  clientLongName: string;
  totalFloating3M: MarginByMaturity[];
  totalFloating6M: MarginByMaturity[];
  totalFloating12M: MarginByMaturity[];
}

export const clientFloatingMarginsApi = (fetch: Fetch): ClientFloatingMarginsApi => ({
  getClientFloatingMargins: async () => {
    const res = await fetch('/api/floatingMargins');
    ensureSuccessStatusCode(res);
    return ((await res.json()) as BackendFloatingMargin[]).map(m =>
      backendToStoreFloatingMargin(m),
    );
  },
});
