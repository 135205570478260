import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { Selectors } from 'store';
import { ActionCreators } from 'store';
import { AppState } from 'store';

export interface PcruFilterConnectOwnProps {
  value: number;
}

export interface PcruFilterConnectStateProps {
  label: string;
  active: boolean;
}

export interface PcruFilterConnectDispatchProps {
  toggle: () => void;
}

// you can whitelist selectors after implementation
export type PcruFilterConnectSelectors = Selectors;
/*
export type PcruFilterConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type PcruFilterConnectSelectors = Pick<Selectors, PcruFilterConnectSelectorsKeys>;
*/

export const mapStateToPropsPcruFilter: MapStateToPropsHOF<
  PcruFilterConnectStateProps,
  PcruFilterConnectOwnProps,
  AppState,
  PcruFilterConnectSelectors
> = sl => (state, { value }) => {
  return {
    label: `${value}bp`,
    active: sl.getFilter(state, 'pcruMargins').includes(value),
  };
};

// you can whitelist action creators after implementation
export type PcruFilterConnectActionCreators = ActionCreators;
/*
export type PcruFilterConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type PcruFilterConnectActionCreators = Pick<ActionCreators, PcruFilterConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsPcruFilter: MapDispatchToPropsHOF<
  PcruFilterConnectDispatchProps,
  PcruFilterConnectOwnProps,
  PcruFilterConnectActionCreators
> = ac => (dispatch, { value }) => ({
  toggle: () => dispatch(ac.filters.pcruMarginFilterToggled(value)),
});
