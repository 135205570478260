import { connect } from 'react-redux';
import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';
import { pcruMargins } from 'store/pcruProfiles';

export interface SelectPcruMarginConnectOwnProps {}

export interface SelectPcruMarginConnectStateProps {
  value: number | undefined;
  options: readonly [number, string][];
}

export interface SelectPcruMarginConnectDispatchProps {
  onChange(value: number): void;
}

// you can whitelist selectors after implementation
export type SelectPcruMarginConnectSelectors = Selectors;
/*
export type SelectPcruMarginConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type SelectPcruMarginConnectSelectors = Pick<Selectors, SelectPcruMarginConnectSelectorsKeys>;
*/

const options: [number, string][] = pcruMargins.map(value => [value, `${value}bp`]);

export const mapStateToPropsSelectPcruMargin: MapStateToPropsHOF<
  SelectPcruMarginConnectStateProps,
  SelectPcruMarginConnectOwnProps,
  AppState,
  SelectPcruMarginConnectSelectors
> = sl => state => ({
  value: sl.getClientFormValue(state, 'pcruMargin'),
  options,
});

// you can whitelist action creators after implementation
export type SelectPcruMarginConnectActionCreators = ActionCreators;
/*
export type SelectPcruMarginConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type SelectPcruMarginConnectActionCreators = Pick<ActionCreators, SelectPcruMarginConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsSelectPcruMargin: MapDispatchToPropsHOF<
  SelectPcruMarginConnectDispatchProps,
  SelectPcruMarginConnectOwnProps,
  SelectPcruMarginConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  onChange(pcruMargin: number) {
    dispatch(
      ac.clientForm.clientFormFieldChanged({
        pcruMargin,
      }),
    );
  },
});

export const connectSelectPcruMargin = connect(
  mapStateToPropsSelectPcruMargin(selectors),
  mapDispatchToPropsSelectPcruMargin(actionCreators),
);
