import { MapDispatchToProps, connect } from 'react-redux';
import { actionCreators } from 'store';

export interface MarginPresetsDispatchProps {
  goBack(): void;
}

const mapDispatchToProps: MapDispatchToProps<MarginPresetsDispatchProps, {}> = dispatch => ({
  goBack() {
    dispatch(actionCreators.ui.pageChanged('ClientsMargins'));
  },
});

export const connectMarginsPresets = connect(undefined, mapDispatchToProps);
