import { AppThunk } from 'store';

export const fetchMarginProfiles = (): AppThunk<Promise<void>> => async (
  dispatch,
  _getState,
  { api, actionCreators },
) => {
  dispatch(actionCreators.marginProfiles.fetch());
  const marginProfiles = await api.marginProfiles.getMarginProfiles();
  dispatch(actionCreators.marginProfiles.fetched(marginProfiles));
};
