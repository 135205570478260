interface MyWindow extends Window {
  // global variable to communicate with piwik tracking code
  _paq: any[];
}

declare let window: MyWindow;

export interface IMatomoConfig {
  url: string;
  siteId: string;
}

type Category =
  | 'Clients margins'
  | 'Margins presets'
  | 'Client modal'
  | 'Crash modal'
  | 'Workspace';
type Action = string;

export interface Matomo {
  init(login: string): void;
  track(category: Category, event: Action, additionalData?: string): void;
}

export class MatomoUtils implements Matomo {
  private _matomoSourceUrl: string;

  constructor(config: IMatomoConfig) {
    const currentDomain = window.location.hostname;
    window._paq = [];
    window._paq.push(['setDomains', [currentDomain]]);
    window._paq.push(['trackPageView']);
    window._paq.push(['enableLinkTracking']);
    window._paq.push(['setTrackerUrl', config.url + 'piwik.php']);
    window._paq.push(['setSiteId', config.siteId]);
    this._matomoSourceUrl = config.url + 'piwik.js';
  }

  private appendMatomoScript(): void {
    const matomoScript = document.createElement('script');
    matomoScript.type = 'text/javascript';
    matomoScript.async = true;
    matomoScript.defer = true;
    matomoScript.src = this._matomoSourceUrl;

    const firstDocumentScriptNode = document.getElementsByTagName('script')[0];
    if (firstDocumentScriptNode === null || firstDocumentScriptNode.parentNode === null) {
      console.error('Can not inject piwik source code into document');
      return;
    }
    firstDocumentScriptNode.parentNode.insertBefore(matomoScript, firstDocumentScriptNode);
  }

  public init(login: string) {
    window._paq.push(['setUserId', login]);
    this.appendMatomoScript();
  }

  private trackEvent(category: Category, eventName: string, additionalData?: string) {
    const data = ['trackEvent', category, eventName];
    if (additionalData !== undefined) {
      data.push(additionalData);
    }
    window._paq.push(data);
    return;
  }

  public track(category: Category, event: Action, additionalData?: string): void {
    this.trackEvent(category, event, additionalData);
  }
}
