import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActiveFilter } from 'store/filters/filtersSelectors';
import { connectActiveFilters } from './connectActiveFilters';

export interface ActiveFiltersProps {
  filters: readonly ActiveFilter[];
  clearFilter(filter: ActiveFilter): void;
  clearAll(): void;
}

const FilterLabel: React.FunctionComponent<{ filter: ActiveFilter }> = ({ filter }) => {
  switch (filter.type) {
    case 'Sales':
    case 'DevL':
    case 'Pcru':
      return <>{filter.label}</>;
    case 'Custom':
      return <FormattedMessage id="filters.customizedMarginsOnly" />;
    case 'MaturityRange':
      return (
        <FormattedMessage
          id="filters.maturityRangeActive"
          values={{ min: filter.min, max: filter.max }}
        />
      );
  }
};

const ActiveFiltersRaw: React.FunctionComponent<ActiveFiltersProps> = ({
  filters,
  clearFilter,
  clearAll,
}) => {
  const handleClear = useCallback((filter: ActiveFilter) => () => clearFilter(filter), [
    clearFilter,
  ]);
  return filters.length > 0 ? (
    <div className="border-top mx-n4 px-4 py-3">
      <span className="font-weight-bold mr-2">Applied filters</span>
      {filters.map((filter, i) => (
        <React.Fragment key={i}>
          <span className="badge badge-discreet-info text-small">
            <i className="icon" onClick={handleClear(filter)}>
              close
            </i>
            <FilterLabel filter={filter} />
          </span>{' '}
        </React.Fragment>
      ))}
      <button type="button" className="btn btn-link" onClick={clearAll}>
        Clear all
      </button>
    </div>
  ) : null;
};

export const ActiveFilters = connectActiveFilters(ActiveFiltersRaw);
