import { createSelector } from '@reduxjs/toolkit';
import { Selector, AppState } from 'store';
import { FiltersState } from './filtersSlice';
import { getMarginProfiles } from 'store/marginProfiles';
import { minimumLowerMaturityRange, maximumUpperMaturityRange } from 'store/clients';

export const getFilters: Selector<FiltersState, Pick<AppState, 'filters'>> = (state) =>
  state.filters;

export const getFilter = <K extends keyof FiltersState>(
  state: AppState,
  filter: K,
): FiltersState[K] => getFilters(state)[filter];

export type ActiverFilterType = 'Sales' | 'DevL' | 'Pcru' | 'Custom' | 'MaturityRange';
export interface ActiveFilterCustomized {
  type: 'Custom';
}
export interface ActiveFilterMargin {
  type: 'Sales' | 'DevL' | 'Pcru';
  label: string;
  value: number;
}
export interface ActiverFilterMaturityRange {
  type: 'MaturityRange';
  min: number;
  max: number;
}
export type ActiveFilter = ActiveFilterCustomized | ActiveFilterMargin | ActiverFilterMaturityRange;

export const getActiveFilters = createSelector(
  getFilters,
  getMarginProfiles,
  (filters, marginProfiles) =>
    [
      ...(filters.lowerMaturityRange > minimumLowerMaturityRange ||
      filters.upperMaturityRange < maximumUpperMaturityRange
        ? [
            {
              type: 'MaturityRange',
              min: filters.lowerMaturityRange,
              max: filters.upperMaturityRange,
            },
          ]
        : []),
      ...(filters.customizedMarginsOnly
        ? [
            {
              type: 'Custom',
              label: 'Custom',
            },
          ]
        : filters.salesProfiles.map((value) => ({
            type: 'Sales',
            label: marginProfiles[value]?.name,
            value,
          }))),
      ...filters.devLProfiles.map((value) => ({
        type: 'DevL',
        label: marginProfiles[value]?.name,
        value,
      })),
      ...filters.pcruMargins.map((value) => ({
        type: 'Pcru',
        label: `${value}bp`,
        value,
      })),
    ] as ActiveFilter[],
);
