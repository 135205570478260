import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { length } from 'utils/object';

import { Client } from 'store/clients';
import { FloatingMargin } from 'store/floatingMargins';

export interface ClientEditionState {
  isEditing: boolean;
  isSaving: boolean;
  isReasonModalOpen: boolean;
  diffs: ClientsDiffs;
}

export interface ClientsDiffs {
  [clientId: number]: ClientDiff;
}

export type ClientDiff = {
  salesMarginProfileId: number | undefined;
  devLMarginProfileId: number | undefined;
  pcruMargin: number | undefined;
  changeReason: string | undefined;
};

const initialState: ClientEditionState = {
  isEditing: false,
  isSaving: false,
  isReasonModalOpen: false,
  diffs: {},
};

export const clientEditionSlice = createSlice({
  name: 'clientEdition',
  initialState,
  reducers: {
    toggleEdition: state => {
      state.isEditing = !state.isEditing;
      if (!state.isEditing) {
        state.diffs = {};
      }
    },
    reasonModalToggled: state => {
      state.isReasonModalOpen = !state.isReasonModalOpen;
    },
    marginProfileModified: (
      state,
      { payload: { clientId, diff } }: PayloadAction<MarginProfileModifiedPayload>,
    ) => {
      state.diffs[clientId] = state.diffs[clientId] ?? {};
      state.diffs[clientId] = {
        ...state.diffs[clientId],
        ...diff,
      };
    },
    marginProfileResetted: (
      state,
      { payload: { clientId, diff } }: PayloadAction<MarginProfileResettedPayload>,
    ) => {
      if (state.diffs[clientId] === undefined) return;
      diff.forEach(key => delete state.diffs[clientId][key]);
      if (length(state.diffs[clientId]) === 0) {
        delete state.diffs[clientId];
      }
    },
    editionSaveSent: state => {
      state.isSaving = true;
    },
    editionSaved: (
      _state,
      _action: PayloadAction<{ clients: Client[]; floatingMargins: FloatingMargin[] }>,
    ) => initialState,
  },
});

export interface MarginProfileModifiedPayload {
  clientId: number;
  diff: Partial<ClientDiff>;
}

export interface MarginProfileResettedPayload {
  clientId: number;
  diff: (keyof ClientDiff)[];
}
