import { Fetch, ensureSuccessStatusCode } from './utils';

export interface ClientsApi {
  getClients(): Promise<BackendClient[]>;
  addClient(client: AddClientPayload): Promise<BackendClient>;
  updateClient(client: UpdateClientPayload): Promise<BackendClient>;
  updateClients(clientsPresets: UpdateClientPayload[]): Promise<BackendClient[]>;
}

export interface BackendClient {
  id: number;
  name: string;
  salesMarginProfileId: number;
  salesMarginProfileName: string;
  devLMarginProfileId: number;
  devLMarginProfileName: string;
  pcruMargin: number;
  lowerMaturityRange: number;
  upperMaturityRange: number;
  isActive: boolean;
}

export type AddClientPayload = Pick<
  BackendClient,
  | 'name'
  | 'salesMarginProfileId'
  | 'devLMarginProfileId'
  | 'pcruMargin'
  | 'lowerMaturityRange'
  | 'upperMaturityRange'
  | 'isActive'
>;
export type UpdateClientPayload = Pick<BackendClient, 'id' | 'name'> &
  Partial<
    Pick<
      BackendClient,
      | 'salesMarginProfileId'
      | 'devLMarginProfileId'
      | 'pcruMargin'
      | 'lowerMaturityRange'
      | 'upperMaturityRange'
      | 'isActive'
    > & {
      reasonForChange: string;
    }
  >;

export const clientsApi = (fetch: Fetch): ClientsApi => ({
  getClients: async () => {
    const res = await fetch('/api/clients');
    ensureSuccessStatusCode(res);
    return await res.json();
  },
  addClient: async (client: AddClientPayload) => {
    const res = await fetch('/api/clients', { method: 'POST', body: JSON.stringify(client) });
    ensureSuccessStatusCode(res);
    return await res.json();
  },
  updateClient: async (client: UpdateClientPayload) => {
    const res = await fetch('/api/clients', { method: 'PATCH', body: JSON.stringify([client]) });
    ensureSuccessStatusCode(res);
    return (await res.json())[0];
  },
  updateClients: async (clients: UpdateClientPayload[]) => {
    const res = await fetch('/api/clients', { method: 'PATCH', body: JSON.stringify(clients) });
    ensureSuccessStatusCode(res);
    return await res.json();
  },
});
