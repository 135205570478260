import React from 'react';
import { useIntl } from 'react-intl';
import { ProfileFilter } from '../Filter/index';
import { FilterGroup } from './FilterGroup';
import { connectDevLFilters } from './connect/connectDevLFilters';

export interface DevLProfileFilterProps {
  ids: number[];
}

const DevLProfileFiltersRaw: React.FunctionComponent<DevLProfileFilterProps> = ({ ids }) => {
  const { formatMessage } = useIntl();
  return (
    <FilterGroup label={formatMessage({ id: 'filters.devLMargin' })}>
      {ids.map(id => (
        <ProfileFilter key={id} marginProfileId={id} type={'DevL'} />
      ))}
    </FilterGroup>
  );
};

export const DevLProfileFilters = connectDevLFilters(DevLProfileFiltersRaw);
