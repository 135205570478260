declare global {
  interface Window {
    sgmeConfiguration: AppConfig;
  }
}

export interface AppConfig {
  env: string;
  sgConnect_authorizationEndpoint: string;
  sgConnect_clientId: string;
  sgConnect_scopes: string;
  sgConnect_unauthorize_redirect_uri: string;
  icSoaUrl: string;
  agGridLicenseKey: string;
  apiUrl: string;
  matomo_url: string;
  matomo_siteid: string;
  helpCenter_sendMailto: string;
}

const defaultConfig: AppConfig = {
  env: process.env.REACT_APP_ENV!,
  sgConnect_authorizationEndpoint: process.env.REACT_APP_SGCONNECT_AUTHORIZATION_ENDPOINT!,
  sgConnect_clientId: process.env.REACT_APP_SGCONNECT_CLIENT_ID!,
  sgConnect_scopes: 'openid profile mail api.sgme-wmc-web.v1 api.manage-commercial-clients.v2',
  sgConnect_unauthorize_redirect_uri: process.env.REACT_APP_SGCONNECT_UNAUTHORIZE_REDIRECT_URI!,
  icSoaUrl: process.env.REACT_APP_IC_SOA_URL!,
  agGridLicenseKey:
    'SHI_International_France_on_behalf_of_Societe_Generale_MultiApp_1Devs29_March_2019__MTU1MzgxNzYwMDAwMA==e5780410bf1a362ba341437bac61c3f7',
  apiUrl: process.env.REACT_APP_API_URL!,
  matomo_url: '',
  matomo_siteid: '',
  helpCenter_sendMailto: process.env.REACT_APP_HELP_CENTER_SEND_MAIL_TO!,
};

const externalConfiguration = window.sgmeConfiguration;

export const appConfig =
  process.env.REACT_APP_USE_EXTERNAL_CONFIG === 'true'
    ? { ...defaultConfig, ...externalConfiguration }
    : defaultConfig;

export const isProduction = () => appConfig.env === 'PROD';
