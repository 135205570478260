import {
  FetchableState,
  defaultFetchableState,
  createFetchableReducers,
} from 'store/utils/fetchable';
import { MarginByMaturity } from 'store/marginProfiles';
import { clientEditionSlice } from 'store/clientEdition';
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { clientDuplicationSlice } from 'store/clientDuplication/clientDuplicationSlice';
import { clientFormSlice } from 'store/clientForm';
import { marginProfilesEditionSlice } from 'store/marginProfilesEdition/marginProfilesEditionSlice';

export interface FloatingMargin {
  id: string;
  totalFloating3M: MarginByMaturity[];
  totalFloating6M: MarginByMaturity[];
  totalFloating12M: MarginByMaturity[];
}

export const floatingMarginsAdapter = createEntityAdapter<FloatingMargin>();

const upsertAll = (
  state: EntityState<FloatingMargin>,
  { payload: { floatingMargins } }: PayloadAction<{ floatingMargins: FloatingMargin[] }>,
) => {
  floatingMarginsAdapter.upsertMany(state, floatingMargins);
};

type FloatingMarginsState = EntityState<FloatingMargin> & FetchableState;

const initialState: FloatingMarginsState = {
  ...floatingMarginsAdapter.getInitialState(),
  ...defaultFetchableState,
};

export const floatingMarginsSlice = createSlice({
  name: 'floatingMargins',
  initialState,
  reducers: {
    ...createFetchableReducers<FloatingMarginsState, FloatingMargin>({
      fetched: floatingMarginsAdapter.addMany,
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(clientEditionSlice.actions.editionSaved, upsertAll)
      .addCase(clientDuplicationSlice.actions.duplicationSubmitReceived, upsertAll)
      .addCase(clientFormSlice.actions.clientFormSubmitReceived, upsertAll)
      .addCase(marginProfilesEditionSlice.actions.editionSaved, upsertAll),
});
