import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { AppState, Selectors, ActionCreators, selectors, actionCreators } from 'store';
import { connect } from 'react-redux';
import { narrowDefined } from 'utils/asserts';

export interface SelectReasonConnectOwnProps {
  clientId: number;
}

export interface SelectReasonConnectStateProps {
  clientName: string;
  value: string | undefined;
}

export interface SelectReasonConnectDispatchProps {
  onChange(value: string): void;
}

// you can whitelist selectors after implementation
export type SelectReasonConnectSelectors = Selectors;
/*
export type SelectReasonConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type SelectReasonConnectSelectors = Pick<Selectors, SelectReasonConnectSelectorsKeys>;
*/

export const mapStateToPropsSelectReason: MapStateToPropsHOF<
  SelectReasonConnectStateProps,
  SelectReasonConnectOwnProps,
  AppState,
  SelectReasonConnectSelectors
> = (sl) => (state, { clientId }) => ({
  clientName: narrowDefined(sl.getClientById(state, clientId)).name,
  value: sl.getEditedClientsDiffs(state)[clientId]?.changeReason,
});

// you can whitelist action creators after implementation
export type SelectReasonConnectActionCreators = ActionCreators;
/*
export type SelectReasonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type SelectReasonConnectActionCreators = Pick<ActionCreators, SelectReasonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsSelectReason: MapDispatchToPropsHOF<
  SelectReasonConnectDispatchProps,
  SelectReasonConnectOwnProps,
  SelectReasonConnectActionCreators
> = (ac) => (dispatch, { clientId }) => ({
  onChange: (changeReason) =>
    dispatch(
      ac.clientEdition.marginProfileModified({
        clientId,
        diff: { changeReason },
      }),
    ),
});

export const connectSelectReason = connect(
  mapStateToPropsSelectReason(selectors),
  mapDispatchToPropsSelectReason(actionCreators),
);
