import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UiState {
  activePage: Page;
  hasAuthExpired: boolean;
}

export type Page = 'ClientsMargins' | 'MarginsPresets';

const initialState: UiState = {
  activePage: 'ClientsMargins',
  hasAuthExpired: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    appBootstrapped: (state, _action: PayloadAction<string>) => state,
    authExpired: state => {
      state.hasAuthExpired = true;
    },
    pageChanged: (state, { payload }: PayloadAction<Page>) => {
      state.activePage = payload;
    },
  },
});
