import { useFeatureToggles } from 'components/hooks/useFeatureToggles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Products } from 'store/displayedProducts';
import { connectMarginsPresets } from './connect/connectMarginsPresets';
import { EditMarginPresets } from './EditMarginsPresets';
import { MarginsPresetsTable } from './MarginsPresetsTable';
import { ProductFilter } from './ProductFilter';

export interface MarginsPresetsProps {
  goBack(): void;
}

const MarginsPresetsRaw: React.FunctionComponent<MarginsPresetsProps> = ({ goBack }) => {
  const featureToggles = useFeatureToggles();
  const [productFilter, setProductFilter] = useState<Products[]>([]);
  return (
    <>
      <div className="border-bottom p-4">
        <button className="btn btn-icon-text btn-link text-large" onClick={goBack}>
          <i className="icon">arrow_back</i>
          <FormattedMessage id="common.back" />
        </button>
        <h1 className="display-3 mt-2 mb-0">
          <FormattedMessage id="marginsPresets.title" />
        </h1>
      </div>
      <div className="p-4 flex-grow-1 d-flex flex-column">
        {!featureToggles.capFlooredActivated && (
          <div className="mb-2">
            <ProductFilter displayedProducts={productFilter} onSelectProduct={setProductFilter} />
          </div>
        )}
        <EditMarginPresets />
        <MarginsPresetsTable productFilter={productFilter} />
      </div>
    </>
  );
};

export const MarginsPresets = connectMarginsPresets(MarginsPresetsRaw);
