import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { Selectors, selectors, actionCreators } from 'store';
import { ActionCreators } from 'store';
import { AppState } from 'store';
import { connect } from 'react-redux';

export interface ClientSearchConnectOwnProps {}

export interface ClientSearchConnectStateProps {
  clientName: string;
}

export interface ClientSearchConnectDispatchProps {
  onClientNameChange(value: string): void;
}

export type ClientSearchConnectSelectors = Selectors;

export const mapStateToPropsClientSearch: MapStateToPropsHOF<
  ClientSearchConnectStateProps,
  ClientSearchConnectOwnProps,
  AppState,
  ClientSearchConnectSelectors
> = sl => state => ({
  clientName: sl.getFilter(state, 'clientName'),
});

export type ClientSearchConnectActionCreators = ActionCreators;

export const mapDispatchToPropsClientSearch: MapDispatchToPropsHOF<
  ClientSearchConnectDispatchProps,
  ClientSearchConnectOwnProps,
  ClientSearchConnectActionCreators
> = ac => dispatch => ({
  onClientNameChange(value) {
    dispatch(ac.filters.clientNameFilterChanged(value));
  },
});

export const connectClientSearch = connect(
  mapStateToPropsClientSearch(selectors),
  mapDispatchToPropsClientSearch(actionCreators),
);
