import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const products = ['fixed', '3M', '6M', '12M'] as const;
export type Products = typeof products[number];

export const initialState: Products[] = [];

export const displayedProductsSlice = createSlice({
  name: 'displayedProducts',
  initialState,
  reducers: {
    displayedProductsChanged: (state, { payload }: PayloadAction<Products[]>) => {
      state = payload;
      return state;
    },
  },
});
