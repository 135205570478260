import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MarginProfile } from 'store/marginProfiles/marginProfilesSlice';
import { FloatingMargin } from 'store/floatingMargins';

export interface MarginProfileEditionState {
  isEditing: boolean;
  isSaving: boolean;
  diffs: MarginProfileDiffs;
}

export interface MarginProfileDiffs {
  [marginId: string]: number;
}

const initialState: MarginProfileEditionState = {
  isEditing: false,
  isSaving: false,
  diffs: {},
};

export const marginProfilesEditionSlice = createSlice({
  name: 'marginProfileEdition',
  initialState,
  reducers: {
    editionToggled: state => {
      if (state.isSaving) return;

      state.isEditing = !state.isEditing;
      if (!state.isEditing) {
        state.diffs = {};
      }
    },
    editionSaveSent: state => {
      state.isSaving = true;
    },
    editionSaved: (
      _state,
      _action: PayloadAction<{
        marginProfiles: MarginProfile[];
        floatingMargins: FloatingMargin[];
      }>,
    ) => initialState,
    marginModified: (
      state,
      { payload: { marginId, value } }: PayloadAction<MarginModifiedPayload>,
    ) => {
      if (!state.isEditing) return;
      state.diffs[marginId] = value;
    },
    marginResetted: (state, { payload: { marginId } }: PayloadAction<MarginResettedPayload>) => {
      if (state.diffs[marginId] === undefined) return;
      delete state.diffs[marginId];
    },
  },
});

export interface MarginModifiedPayload {
  marginId: string;
  value: number;
}

export interface MarginResettedPayload {
  marginId: string;
}
