import { BackendFloatingMargin } from 'api/clientFloatingMargins';
import { FloatingMargin } from './floatingMarginsSlice';

export const backendToStoreFloatingMargin = (
  floatingMargin: BackendFloatingMargin,
): FloatingMargin => {
  const { clientLongName, ...rest } = floatingMargin;
  // some clients have weird spaces in their name, and thus break comparison
  return { ...rest, id: clientLongName.trim() };
};
