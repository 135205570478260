import { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-connect';
import { Selectors, selectors, actionCreators } from 'store';
import { ActionCreators } from 'store';
import { AppState } from 'store';
import { connect } from 'react-redux';

export interface ActiveClientConnectOwnProps {}

export interface ActiveClientConnectStateProps {
  activeClientsOnly: boolean;
}

export interface ActiveClientConnectDispatchProps {
  onActiveClientsOnlyToggle(): void;
}

export type ActiveClientConnectSelectors = Selectors;

export const mapStateToPropsActiveClient: MapStateToPropsHOF<
  ActiveClientConnectStateProps,
  ActiveClientConnectOwnProps,
  AppState,
  ActiveClientConnectSelectors
> = sl => state => ({
  activeClientsOnly: sl.getFilter(state, 'activeClientsOnly'),
});

export type ActiveClientConnectActionCreators = ActionCreators;

export const mapDispatchToPropsActiveClient: MapDispatchToPropsHOF<
  ActiveClientConnectDispatchProps,
  ActiveClientConnectOwnProps,
  ActiveClientConnectActionCreators
> = ac => dispatch => ({
  onActiveClientsOnlyToggle() {
    dispatch(ac.filters.activeClientsOnlyFilterToggled());
  },
});

export const connectActiveClient = connect(
  mapStateToPropsActiveClient(selectors),
  mapDispatchToPropsActiveClient(actionCreators),
);
