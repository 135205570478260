import { connect } from 'react-redux';
import { MapDispatchToPropsHOF, MapStateToPropsHOF } from 'typings/redux-connect';
import { AppState, ActionCreators, actionCreators, Selectors, selectors } from 'store';
import { ActiveFilter } from 'store/filters/filtersSelectors';
import { assertUnreachable } from 'utils/asserts';
import { minimumLowerMaturityRange, maximumUpperMaturityRange } from 'store/clients';

export interface ActiveFiltersStateProps {
  filters: readonly ActiveFilter[];
}

export interface ActiveFiltersDispatchProps {
  clearFilter(filter: ActiveFilter): void;
  clearAll(): void;
}

const mapStateToProps: MapStateToPropsHOF<ActiveFiltersStateProps, {}, AppState, Selectors> = (
  sl,
) => (state) => ({
  filters: sl.getActiveFilters(state),
});

const mapDispatchToProps: MapDispatchToPropsHOF<ActiveFiltersDispatchProps, {}, ActionCreators> = (
  ac,
) => (dispatch) => ({
  clearFilter(filter) {
    switch (filter.type) {
      case 'Sales':
        dispatch(ac.filters.salesProfileFilterToggled(filter.value));
        break;
      case 'DevL':
        dispatch(ac.filters.devLProfileFilterToggled(filter.value));
        break;
      case 'Pcru':
        dispatch(ac.filters.pcruMarginFilterToggled(filter.value));
        break;
      case 'Custom':
        dispatch(ac.filters.customizedMarginsOnlyFilterToggled());
        break;
      case 'MaturityRange':
        dispatch(ac.filters.lowerMaturityRangeChanged(minimumLowerMaturityRange));
        dispatch(ac.filters.upperMaturityRangeChanged(maximumUpperMaturityRange));
        break;
      default:
        assertUnreachable(filter, 'Unhandled filter type');
    }
  },
  clearAll() {
    dispatch(ac.filters.filtersCleared());
  },
});

export const connectActiveFilters = connect(
  mapStateToProps(selectors),
  mapDispatchToProps(actionCreators),
);
